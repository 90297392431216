import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import services from '../../../services/services'
import servicesSupply from '../../../services/servicesSupply'
import { getHeaders } from '../../../common/helper'
import { prepareMarketData, dashBoardUpdatedResponse, publisherDomainListUpdatedResponse, ContentCategoryLoader, prepareSupplyData, disableDataSources } from '../campaign-optimization/helper'

export const getDealBasicData = createAsyncThunk(
  'get/basicDealData',
  async ({oktaUserId, accessToken }) => {
    const res = await services.getDealBasicData(
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

export const getSSPDealBasicData = createAsyncThunk(
  'get/sspbasicDealData',
  async ({ssp_id, oktaUserId, accessToken }) => {
    const res = await services.getSSPDealBasicData(
      ssp_id,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

export const getDealMarket = createAsyncThunk(
  'get/deal-setup/market/list',
  async ({ oktaUserId, accessToken }) => {
    const res = await services.getDealMarket(
      getHeaders(oktaUserId, accessToken)
    )
    prepareMarketData(res?.data)
    return res?.data
  }
)
export const getAllDealData = createAsyncThunk(
  '/deal-setup/deal/list',

  async ({ oktaUserId, accessToken }) => {
    const res = await services.getAllDealData(
      getHeaders(oktaUserId, accessToken)
    )
    const result = dashBoardUpdatedResponse(res?.data?.data)
    return await result
  }
)

export const postDealCreation = createAsyncThunk(
  'deal-setup/create-deal',
  async ({ data, sspData, oktaUserId, accessToken }) => {
    const res = await services.postDealCreation(
      data,
      sspData,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

export const getDealData = createAsyncThunk(
  'get/deal_setup/get_deal',
  async ({ id, deal_type, ssp_name, oktaUserId, accessToken }) => {
    const res = await services.getDealData(
      id,
      deal_type,
      ssp_name,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

export const deactivateDeal = createAsyncThunk(
  "put/deal-setup/deal_li/status",
  async ({ data, id, sspName, oktaUserId, accessToken }) => {
    const res = await services.deactivateDeal(
      data,
      id,
      sspName,
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
);




export const dealNameVerify = createAsyncThunk(
  'post/deal-setup/deal-name/verify',
  async ({ req_data, accessToken, oktaUserId }) => {
    const res = await services.dealNameVerify(
      req_data,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

export const getPublisherList = createAsyncThunk(
  'post/deal-setup/publisher_inventory_details',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await services.getPublisherList(
      data,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

export const postDomainList = createAsyncThunk(
  '/deal-setup/domain_inventory_details',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await services.postDomainList(
      data,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

export const postIABContentCategoryList = createAsyncThunk(
  '/deal-setup/Iab_content_details',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await services.postIABContentCategoryList(
      data,
      getHeaders(oktaUserId, accessToken)
    )
    ContentCategoryLoader(res?.data)
    return res?.data
  }
)

export const putEditDealData = createAsyncThunk(
  '/deal-setup/edit_deal',
  async ({ data, id, deal_id, exchange_name, oktaUserId, accessToken }) => {
    const res = await services.putEditDealData(
      data,
      id,
      deal_id,
      exchange_name,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

export const getDomainLuanguageList = createAsyncThunk(
  'get/deal-setup/domain_language_inventory_details',
  async ({ oktaUserId, accessToken }) => {
    const res = await services.getDomainLuanguageList(
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

export const getCorePublisher = createAsyncThunk(
  'deal-setup/local_publisher_inventory_details',
  async ({ oktaUserId, accessToken }) => {
    const res = await services.getCorePublisher(
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

export const getPublisherDomainsList = createAsyncThunk(
  '/deal-setup/publisher_domain_inventory_details',
  async ({ data, deal_type, start_with, oktaUserId, accessToken }) => {
    const res = await services.getPublisherDomainsList(
      data,
      deal_type,
      start_with,
      getHeaders(oktaUserId, accessToken)
    )
    const result = await publisherDomainListUpdatedResponse(res?.data?.data)
    return result
  }
)

export const getPublisherDomainsCount = createAsyncThunk(
  '/deal-setup/publisher_domain_inventory_details/count',
  async ({ data, deal_type, oktaUserId, accessToken }) => {
    const res = await services.getPublisherDomainsCount(
      data,
      deal_type,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

export const getPublisherDomainSearchResult = createAsyncThunk(
  '/deal-setup/publisher_domain_inventory_details/search',
  async ({ data, search_key, oktaUserId, accessToken }) => {
    const res = await services.getPublisherDomainSearchResult(
      data,
      search_key,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

export const viewAllDomainSearch = createAsyncThunk(
  '/deal-setup/publisher_domain_inventory_details/viewDomains',
  async ({ data, searchKey, view_domain, oktaUserId, accessToken }) => {
    const res = await services.viewAllDomainSearch(
      data,
      searchKey,
      view_domain,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

export const getVendorsSegments = createAsyncThunk(
  '/deal-setup/vendor_segment_details',
  async ({ ssp_name, category, oktaUserId, accessToken }) => {
    const res = await services.getVendorsSegments(
      ssp_name,
      category,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)


export const postForeCastData = createAsyncThunk(
  '/deal-setup/forecast',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await services.postForeCastData(
      data,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

export const postCalculateCpm = createAsyncThunk(
  '/deal-setup/calculate-cpm',
  async ({ data, oktaUserId, accessToken }) => {

    const res = await services.postCalculateCpm(
      data,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)
export const getDataSourceType = createAsyncThunk(
  'get/supply-manager/data_provider',
  async ({ oktaUserId, accessToken }) => {
    const res = await servicesSupply.getDataSourceType(
      getHeaders(oktaUserId, accessToken)
    ) 
    disableDataSources(res.data.data)
    return await res.data.data

  }
)
export const InventoryListVerify = createAsyncThunk(
  'post/supply-manager/verify_inventory_list_name',
  async ({ req_data, accessToken, oktaUserId }) => {
    const res = await servicesSupply.InventoryListVerify(
      req_data,
      getHeaders(oktaUserId, accessToken)
    )
    prepareSupplyData(res?.data)
    return res?.data
  }
)

export const getDspList = createAsyncThunk(
  'get/deal-setup/pubmatic/dsp-list',
  async ({ pageNumber, pageSize, searchValue, oktaUserId, accessToken }) => {
    const res = await services.getDspList(
      pageNumber,
      pageSize,
      searchValue,
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
)

export const getDspSeatIDs = createAsyncThunk(
  'get/deal-setup/pubmatic/buyer-list',
  async ({ pageNumber, pageSize, searchValue, dspId, oktaUserId, accessToken }) => {
    const res = await services.getDspSeatIDs(
      pageNumber,
      pageSize,
      searchValue,
      dspId,
      getHeaders(oktaUserId, accessToken)
      );
      return await res.data;
    }
  )
  
export const dealInventoryList = createAsyncThunk(
  '/deal/inventory_list',
  async ({ id, oktaUserId, accessToken }) => {
    const res = await servicesSupply.dealInventoryList(
      id, 
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
)

export const getPubmaticGeolist = createAsyncThunk(
  'get/deal-setup/pubmatic/geo-list',
  async ({ pageNumber, pageSize, name, geoLocation, searchInput, oktaUserId, accessToken }) => {
    const res = await services.getPubmaticGeolist(
      pageNumber,
      pageSize,
      name,
      geoLocation,
      searchInput,
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data.data;
  }
)

export const postTargetingData = createAsyncThunk(
  'post/deal-setup/pubmatic/targeting',
  async ({ data, accessToken, oktaUserId }) => {
    const res = await services.postTargetingData(
      data,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

export const getBrowsersList = createAsyncThunk(
  'get/deal-setup/pubmatic/browser-list',
  async ({ oktaUserId, accessToken }) => {
    const res = await services.getBrowsersList(
      getHeaders(oktaUserId, accessToken)
    );
    return res?.data?.data;
  }
)

const initialState = {
  dealBasicData: [],
  marketData: [],
  postDealData: [],
  getDealInfo: [],
  allDealData: [],
  deactivateDealData: [],
  publisherList: [],
  domainList: [],
  IABContentList: [],
  domainLangugage: [],
  localPublishers: [],
  dealValidation: [],
  foreCast: [],
  publisher_loader: true,
  publisherDomainList: [],
  publisherDomainCount: [],
  publisherDomainSearchResult: {},
  viewAllDomainSearchResult: [],
  getVendorsSegmentsResult: [],
  dealsListLoader: false,
  getDealsLoader: false,
  editDealsLoader: false,
  editDeals: [],
  vendorSegmentLoader: false,
  calculatedCpmValue: [],
  DataSources : [],
  InventoryValidation : [],
  dealInventory_list: [],
  dealSspBasicData: [],
  pubDomainCount : false,
  pubmaticGeoList: false,
  browsersList : [],
}

const resetState = {
  dealBasicData: [],
  marketData: [],
  postDealData: [],
  getDealInfo: [],
  allDealData: [],
  deactivateDealData: [],
  publisherList: [],
  domainList: [],
  IABContentList: [],
  domainLangugage: [],
  localPublishers: [],
  dealValidation: [],
  publisherDomainList: [],
  publisherDomainCount: [],
  publisherDomainSearchResult: {},
  viewAllDomainSearchResult: [],
  getVendorsSegmentsResult: [],
  foreCast: [],
  DataSources : [],
  InventoryValidation : [],
  dealSspBasicData: [],
  pubDomainModal: false,
  searchLoader: false,
}

const targetingDataSlice = createSlice({
  name: 'targetingData',
  initialState: initialState,
  reducers: {
    reset: state => ({ ...state, ...resetState })
  },
  extraReducers: {
    [getDealBasicData.pending]: state => {
      state.loading = true
      state.dealBasicData = []
      state.publisherDomainList = []
    },
    [getDealBasicData.rejected]: (state, action) => {
      state.loading = false
      state.dealBasicData = []
      state.rejected = action
    },
    [getDealBasicData.fulfilled]: (state, action) => {
      state.loading = false
      state.publisherDomainList = []
      state.dealBasicData = action.payload
    },
    [getDealMarket.pending]: state => {
      state.loading = true
      state.marketData = []
    },
    [getDealMarket.rejected]: (state, action) => {
      state.loading = false
      state.marketData = []
      state.rejected = action
    },
    [getDealMarket.fulfilled]: (state, action) => {
      state.loading = false
      state.marketData = action.payload
    },
    [dealNameVerify.rejected]: (state, action) => {
      state.loading = false
      state.dealValidation = []
      state.rejected = action
    },
    [dealNameVerify.pending]: state => {
      state.loading = true
      state.dealValidation = []
    },
    [dealNameVerify.fulfilled]: (state, action) => {
      state.loading = true
      state.dealValidation = action.payload
    },


    [getAllDealData.pending]: state => {
      state.loading = true
      state.dealsListLoader = true
      state.allDealData = []
    },
    [getAllDealData.rejected]: (state, action) => {
      state.loading = false
      state.dealsListLoader = false
      state.marketData = []
      state.allDealData = action
    },
    [getAllDealData.fulfilled]: (state, action) => {
      state.loading = false
      state.dealsListLoader = false
      state.allDealData = action.payload
    },


    [postDealCreation.pending]: state => {
      state.loading = true
      state.postDealData = []
    },
    [postDealCreation.rejected]: (state, action) => {
      state.loading = false
      state.postDealData = []
      state.rejected = action
    },
    [postDealCreation.fulfilled]: (state, action) => {
      state.loading = false
      state.postDealData = action.payload
    },
    [getDealData.pending]: state => {
      state.loading = true
      state.getDealsLoader = true
      state.getDealInfo = []
      state.publisherDomainList = []
    },
    [getDealData.rejected]: (state, action) => {
      state.loading = false
      state.getDealsLoader = false
      state.getDealInfo = []
      state.rejected = action
    },
    [getDealData.fulfilled]: (state, action) => {
      state.loading = false
      state.getDealsLoader = false
      state.publisherDomainList = []
      state.getDealInfo = action.payload
    },
    [deactivateDeal.pending]: (state) => {
      state.loading = true;
      state.deactivateDeal = [];
    },
    [deactivateDeal.rejected]: (state, action) => {
      state.loading = false;
      state.deactivateDeal = [];
      state.rejected = action;
    },
    [deactivateDeal.fulfilled]: (state, action) => {
      state.loading = false;
      state.deactivateDeal = action.payload;
    },
    [getPublisherList.pending]: state => {
      state.loading = true
      state.pubDomainModal = true
      state.publisherList = []
    },
    [getPublisherList.rejected]: (state, action) => {
      state.loading = false
      state.pubDomainModal = false
      state.publisherList = []
      state.rejected = action
    },
    [getPublisherList.fulfilled]: (state, action) => {
      state.loading = false
      state.pubDomainModal = true
      state.publisherList = action.payload
    },

    [postDomainList.pending]: state => {
      state.domainList = []
    },
    [postDomainList.rejected]: (state, action) => {
      state.domainList = []
      state.rejected = action
    },
    [postDomainList.fulfilled]: (state, action) => {
      state.domainList = action.payload
    },

    [postIABContentCategoryList.pending]: state => {
      state.loading = true
      state.IABContentList = []
    },
    [postIABContentCategoryList.rejected]: (state, action) => {
      state.loading = false
      state.IABContentList = []
      state.rejected = action
    },
    [postIABContentCategoryList.fulfilled]: (state, action) => {
      state.loading = false
      state.IABContentList = action.payload
    },
    [getDomainLuanguageList.pending]: state => {
      state.loading = true
      state.domainLangugage = []
    },
    [getDomainLuanguageList.rejected]: (state, action) => {
      state.loading = false
      state.domainLangugage = []
      state.rejected = action
    },
    [getDomainLuanguageList.fulfilled]: (state, action) => {
      state.loading = false
      state.domainLangugage = action.payload
    },
    [getCorePublisher.pending]: state => {
      state.loading = true
      state.localPublishers = []
    },
    [getCorePublisher.rejected]: (state, action) => {
      state.loading = false
      state.localPublishers = []
      state.rejected = action
    },
    [getCorePublisher.fulfilled]: (state, action) => {
      state.loading = false
      state.localPublishers = action.payload
    },
    [getPublisherDomainsList.pending]: state => {
      state.pubDomainModal = true
      state.publisherDomainList = []
    },
    [getPublisherDomainsList.rejected]: (state, action) => {
      state.pubDomainModal = false
      state.publisherDomainList = []
      state.rejected = action
    },
    [getPublisherDomainsList.fulfilled]: (state, action) => {
      state.pubDomainModal = false
      state.publisherDomainList = action.payload
    },
    [getPublisherDomainsCount.pending]: state => {
      state.pubDomainCount = true
      state.publisherDomainCount = []
    },
    [getPublisherDomainsCount.rejected]: (state, action) => {
      state.pubDomainCount = false
      state.publisherDomainCount = []
      state.rejected = action
    },
    [getPublisherDomainsCount.fulfilled]: (state, action) => {
      state.pubDomainCount = false
      state.publisherDomainCount = action.payload
    },
    [getPublisherDomainSearchResult.pending]: state => {
      state.searchLoader = true
      state.publisherDomainSearchResult = {}
    },
    [getPublisherDomainSearchResult.rejected]: (state, action) => {
      state.searchLoader = false
      state.publisherDomainSearchResult = {}
      state.rejected = action
    },
    [getPublisherDomainSearchResult.fulfilled]: (state, action) => {
      state.searchLoader = false
      state.publisherDomainSearchResult = action.payload
    },
    [viewAllDomainSearch.pending]: state => {
      state.loading = true
      state.viewAllDomainSearchResult = []
    },
    [viewAllDomainSearch.rejected]: (state, action) => {
      state.loading = false
      state.viewAllDomainSearchResult = []
      state.rejected = action
    },
    [viewAllDomainSearch.fulfilled]: (state, action) => {
      state.loading = false
      state.viewAllDomainSearchResult = action.payload
    },
    [getVendorsSegments.pending]: state => {
      state.vendorSegmentLoader = true
      state.getVendorsSegmentsResult = []
    },
    [getVendorsSegments.rejected]: (state, action) => {
      state.vendorSegmentLoader = false
      state.getVendorsSegmentsResult = []
      state.rejected = action
    },
    [getVendorsSegments.fulfilled]: (state, action) => {
      state.vendorSegmentLoader = false
      state.getVendorsSegmentsResult = action.payload
    },
    [postForeCastData.pending]: state => {
      state.loading = true
      state.foreCast = []
    },
    [postForeCastData.rejected]: (state, action) => {
      state.loading = false
      state.foreCast = []
      state.rejected = action
    },
    [postForeCastData.fulfilled]: (state, action) => {
      state.loading = false
      state.foreCast = action.payload
    },
    [putEditDealData.pending]: state => {
      state.loading = true
      state.editDealsLoader = true
      state.editDeals = []
    },
    [putEditDealData.rejected]: (state, action) => {
      state.loading = false
      state.editDealsLoader = false
      state.editDeals = action
    },
    [putEditDealData.fulfilled]: (state, action) => {
      state.loading = false
      state.editDealsLoader = false
      state.editDeals = action.payload
    },
    [postCalculateCpm.pending]: state => {
      state.loading = true
      state.calculatedCpmValue = []
    },
    [postCalculateCpm.rejected]: (state) => {
      state.loading = false
      state.calculatedCpmValue = []
    },
    [postCalculateCpm.fulfilled]: (state, action) => {
      state.loading = false
      state.calculatedCpmValue = action.payload
    },
    [getDataSourceType.pending]: state => {
      state.dataSourceTypesloading = true
      state.DataSources = []
    },
    [getDataSourceType.fulfilled]: (state, action) => {
      state.dataSourceTypesloading = false
      state.DataSources = action.payload
    },

    [InventoryListVerify.rejected]: (state, action) => {
      state.loading = false
      state.InventoryValidation = []
      state.rejected = action
    },
    [InventoryListVerify.pending]: state => {
      state.loading = true
      state.InventoryValidation= []
    },
    [InventoryListVerify.fulfilled]: (state, action) => {
      state.loading = true
      state.InventoryValidation = action.payload
    },
    [dealInventoryList.pending]: state => {
      state.dealInventory_list = []
    },
    [dealInventoryList.rejected]: (state, action) => {
      state.dealInventory_list = []
      state.rejected = action
    },
    [dealInventoryList.fulfilled]: (state, action) => {
      state.dealInventory_list = action.payload
    },
    [getSSPDealBasicData.pending]: state => {
      state.dealSspBasicData = []
    },
    [getSSPDealBasicData.rejected]: (state) => {
      state.dealSspBasicData = []
    },
    [getSSPDealBasicData.fulfilled]: (state, action) => {
      state.dealSspBasicData = action.payload
    },
    [postTargetingData.pending]: state => {
      state.getDealsLoader = true
    },
    [postTargetingData.rejected]: (state) => {
      state.getDealsLoader = false
    },
    [postTargetingData.fulfilled]: (state) => {
      state.getDealsLoader = false
    },
    [getPubmaticGeolist.pending]: state => {
      state.pubmaticGeoList = true
    },
    [getPubmaticGeolist.rejected]: (state) => {
      state.pubmaticGeoList = false
    },
    [getPubmaticGeolist.fulfilled]: (state) => {
      state.pubmaticGeoList = false
    },
    [getBrowsersList.pending]: state => {
      state.browsersList = []
    },
    [getBrowsersList.rejected]: (state) => {
      state.browsersList = []
    },
    [getBrowsersList.fulfilled]: (state, action) => {
      state.browsersList = action.payload
    },
  }
})

export const { reset } = targetingDataSlice.actions

export default targetingDataSlice.reducer