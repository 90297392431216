import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getHeaders } from '../../../common/helper'
import servicesSupply from '../../../services/servicesSupply'
import { prepareGetInventoryList, prepareLoadInventoryList } from '../campaign-optimization/helper'


export const getInventoryList = createAsyncThunk(
  'get/supply_manager/inventory_lists',
  async ({ oktaUserId, accessToken }) => {
    const res = await servicesSupply.getInventoryList(
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

export const getStaticData = createAsyncThunk(
  'post/supply-manager/static/data',
  async ({ req_data, yaml_type, accessToken, oktaUserId }) => {
    const res = await servicesSupply.getStaticData(
      req_data,
      yaml_type,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

export const applyPrefilters = createAsyncThunk(
  'post/supply-manager/create-yaml',
  async ({ req_data, accessToken, oktaUserId, type }) => {
    const res = await servicesSupply.applyPrefilters(
      req_data,
      getHeaders(oktaUserId, accessToken),
      type,
    )
    return res?.data?.data
  }
)

export const getCurateInventoryList = createAsyncThunk(
  'post/supply-manager/apply/rules',
  async ({ req_data, accessToken, oktaUserId, type}) => {
    const res = await servicesSupply.getCurateInventoryList(
      req_data,
      getHeaders(oktaUserId, accessToken),
      type
    )
    prepareGetInventoryList(res?.data?.data)
    return res?.data?.data
  }
)
export const loadCuratedInventoryList = createAsyncThunk(
  'post/supply-manager/load_inventory_list',
  async ({ req_data, accessToken, oktaUserId }) => {
    const res = await servicesSupply.loadCuratedInventoryList(
      req_data,
      getHeaders(oktaUserId, accessToken),
     
    ) 
    prepareLoadInventoryList(res?.data?.data)
    return res?.data?.data
  }
)

export const uploadUpdatedInventoryList = createAsyncThunk(
  'supply-manager/curated_inventory_list_save_file',
  async ({ dataDetails: data, file_extn, data_source, accessToken, oktaUserId }) => {
    const res = await servicesSupply.uploadUpdatedInventoryList(
      data,
      file_extn,
      data_source,
      getHeaders(oktaUserId, accessToken),
     
    ) 
    return res?.data
  }
)


export const deactivateInventoryList= createAsyncThunk(
  "put/deal-setup/deal_li/status",
  async ({ data,  oktaUserId, accessToken }) => {
    const res = await servicesSupply.deactivateInventoryList(
      data,
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
);

export const createInventoryList = createAsyncThunk(
  '/supply-manager/create/inventory',
  async ({ data,  oktaUserId, accessToken }) => {
    const res = await servicesSupply.createInventoryList(
      data,
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
);

export const platformAccountList = createAsyncThunk(
  '/supply-manager/list/accounts',
  async ({ oktaUserId, accessToken }) => {
    const res = await servicesSupply.platformAccountList(
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
);

export const addNewDataSource = createAsyncThunk(
  '/supply-manager/add/data_source',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await servicesSupply.addNewDataSource(
      data,
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
);

export const uploadLogo = createAsyncThunk(
  '/upload/image',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await servicesSupply.uploadLogo(
      data,
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
);

export const viewDataSource = createAsyncThunk(
  '/view/data_source',
  async ({ id, oktaUserId, accessToken }) => {
    const res = await servicesSupply.viewDataSource(
      id,
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
);

export const updateDataSource = createAsyncThunk(
  '/update/data_source',
  async ({ data, id, oktaUserId, accessToken }) => {
    const res = await servicesSupply.updateDataSource(
      data,
      id,
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
);

export const deleteDataSource = createAsyncThunk(
  'delete/data_source',
  async ({ id, oktaUserId, accessToken }) => {
    const res = await servicesSupply.deleteDataSource(
      id, getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
)

export const viewInventoryListData = createAsyncThunk(
  '/view/inventory_list',
  async ({ id, ssp_id, oktaUserId, accessToken }) => {
    const res = await servicesSupply.viewInventoryList(
      id, ssp_id, getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
)

export const editInventoryList = createAsyncThunk(
  '/edit/inventory_list',
  async ({ data, id, ssp_id, oktaUserId, accessToken }) => {
    const res = await servicesSupply.editInventoryList(
      data, 
      id,
      ssp_id, 
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
)

export const exportInventoryList = createAsyncThunk(
  '/download/inventory_list',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await servicesSupply.exportInventoryList(
      data, 
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
)

export const duplicateInventoryList = createAsyncThunk(
  '/duplicate/inventory_list',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await servicesSupply.duplicateInventoryList(
      data, 
      getHeaders(oktaUserId, accessToken)
    );
    return await res.data;
  }
)

const initialState = {
  inventoryList: [],
  inventoryListLoader: false,
  staticData: [],
  applyPrefilters: [],
  curateInventoryList: {},
  inventoryDeactivate : [],
  loadInventoryList : [],
  createInventory : [],
  inventoryLoader: false,
  accountList: [],
  dataSourceLoader: false,
  viewInventoryData: [],
}

const resetState = {
  inventoryList: [],
  staticData: [],
  curateInventoryList: {},
  inventoryDeactivate : [],
  loadInventoryList : [],
  createInventory : [],
  accountList: []
}

const supplyManagementSlice = createSlice({
  name: 'supplyManagerData',
  initialState: initialState,
  reducers: {
    reset: state => ({ ...state, ...resetState })
  },
  extraReducers: {
    [getInventoryList.pending]: state => {
      state.inventoryListLoader = true
      state.inventoryList = []
    },
    [getInventoryList.rejected]: (state, action) => {
      state.linventoryListLoader = false
      state.inventoryList = []
      state.rejected = action
    },
    [getInventoryList.fulfilled]: (state, action) => {
      state.inventoryListLoader = false
      state.inventoryList = action.payload
    },
    [getStaticData.pending]: state => {
      state.loader = true
      state.staticData = []
    },
    [getStaticData.rejected]: (state, action) => {
      state.loader = false
      state.staticData = []
      state.rejected = action
    },
    [getStaticData.fulfilled]: (state, action) => {
      state.loader = false
      state.staticData = action.payload
    },
    [applyPrefilters.pending]: state => {
      state.loader = true
      state.applyPrefilters = []
    },
    [applyPrefilters.rejected]: (state, action) => {
      state.loader = false
      state.applyPrefilters = []
      state.rejected = action
    },
    [applyPrefilters.fulfilled]: (state, action) => {
      state.loader = false
      state.applyPrefilters = action.payload
    },
    [getCurateInventoryList.pending]: state => {
      state.loader = true
      state.curateInventoryList = {}
    },
    [getCurateInventoryList.rejected]: (state, action) => {
      state.loader = false
      state.curateInventoryList = {}
      state.rejected = action
    },
    [getCurateInventoryList.fulfilled]: (state, action) => {
      state.loader = false
      state.curateInventoryList = action.payload
    },


    [loadCuratedInventoryList.pending]: state => {
      state.loader = true
      state.loadInventoryList = {}
    },
    [loadCuratedInventoryList.rejected]: (state, action) => {
      state.loader = false
      state.loadInventoryList = []
      state.rejected = action
    },
    [loadCuratedInventoryList.fulfilled]: (state, action) => {
      state.loader = false
      state.loadInventoryList = action.payload
    },

    [deactivateInventoryList.pending]: state => {
      state.loader = true
      state.inventoryDeactivate = []
    },
    [deactivateInventoryList.rejected]: (state, action) => {
      state.loader = false
      state.inventoryDeactivate = []
      state.rejected = action
    },
    [deactivateInventoryList.fulfilled]: (state, action) => {
      state.loader = false
      state.inventoryDeactivate = action.payload
    },
    [createInventoryList.pending]: state => {
      state.inventoryLoader = true
      state.createInventory = []
    },
    [createInventoryList.rejected]: (state, action) => {
      state.inventoryLoader = false
      state.createInventory = []
      state.rejected = action
    },
    [createInventoryList.fulfilled]: (state, action) => {
      state.inventoryLoader = false
      state.createInventory = action.payload
    },
    [platformAccountList.pending]: state => {
      state.loader = true
      state.accountList = []
    },
    [platformAccountList.rejected]: (state, action) => {
      state.loader = false
      state.accountList = []
      state.rejected = action
    },
    [platformAccountList.fulfilled]: (state, action) => {
      state.loader = false
      state.accountList = action.payload
    },
    [viewDataSource.pending]: state => {
      state.dataSourceLoader = true
    },
    [viewDataSource.rejected]: (state) => {
      state.dataSourceLoader = false
    },
    [viewDataSource.fulfilled]: (state) => {
      state.dataSourceLoader = false
    },
    [viewInventoryListData.pending]: state => {
      state.viewInventoryData = []
    },
    [viewInventoryListData.rejected]: (state, action) => {
      state.viewInventoryData = []
      state.rejected = action
    },
    [viewInventoryListData.fulfilled]: (state, action) => {
      state.viewInventoryData = action.payload
    },
  }
})

export const { reset } = supplyManagementSlice.actions

export default supplyManagementSlice.reducer
