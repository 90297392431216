export const FIELD_NAME_PARTNER_ID = 'partnerId'
export const FIELD_NAME_CAMPAIGN_ID = 'campaignId'
export const FIELD_NAME_ADVERTISER_ID = 'advertiserId'
export const FIELD_NAME_IO_IDS = 'ioIds'
export const PROGRAMMATIC_OPTIMIZER = 'DSP Performance Metrics'
export const ATTENTION_OPTIMIZER = 'Integral Ad Science'
export const PROGRAMMATIC_PLAN_GENERATOR = 'DoubleVerify'
export const FIELD_NAME_LI_ID='liId'
export const SELECT_CAMPAIGN_TO_ENABLE = 'Select a Campaign to Enable'
export const SELECT_CAMPAIGN_SUB_HEADING =
  'Select the relevant insertion orders to enable the optimisation on in Google Display & Video 360'
export const FIELD_SHOULD_NOT_BE_EMPTY = 'Field should not be empty'
export const VALUE_SHOULD_BE_GREATER_THAN_ZERO =
  'Value should be greater than zero'
export const DUPLICATE_KPI_ENTRY = 'Duplicate KPI entry'
export const DE_ACTIVATE = 'Deactivate'
export const FIELD_NAME_CAMPAIGN_START_DATE_AND_END_DATE =
  'campaignStartAndEndDate'
export const FIELD_NAME_CURRENCY_CODE = 'currencyCode'
export const FIELD_NAME_IO_BUDGET = 'ioBudget'
export const CHECKBOX_VALIDATION = 'At least one item should be selected'
export const DATE_VALIDATE = 'Start Date should not be greater than End Date'
export const START_DATE_VALIDATE = 'Please select start date'
export const END_DATE_EMPTY = 'Please select an optimisation end date'
export const NO_GOAL = 'Please select at least one goal'
export const WARNING = 'warning'
export const ERROR = 'Error'
export const POSITIVE = 'positive'
export const SUCCESS = 'Success'
export const CANCEL = 'Cancel'
export const CONTINUE = 'Continue'
export const OK = 'Ok'
export const START_DATE_ERROR_MESSAGE =
  'Start date should not be less than campaign start date'
export const END_DATE_VALIDATE =
  'EndDate date should not be less than Todays date'
export const CAMPAIGN_GOAL_HEADING =
  'Provide the campaign goals for the optimisation script to tune performance for the campaign'
export const SUCCESS_DEACTIVATE_CAMPAIGN = 'Campaign de-activated successfully'
export const FAILURE_DEACTIVATE_CAMPAIGN = 'Campaign has not been De-activated'
export const DAG_TRIGGERED_SUCCESSFULLY="optimisation triggered successfully"
export const NOTIFICATION = 'notification'
export const ENABLE = 'Enable optimisation'
export const SAVE_CHANGES = 'Save changes'
export const EDIT_SETTINGS_TITLE = 'Edit campaign settings - IO'
export const EDIT_SETTING = 'Edit Settings'
export const HISTORICAL_OPTIMISATION = 'Historical Optimisations'
export const FILE_DOWNLOAD_SUCCESS_MESSAGE = 'Files Downloaded Successfully'
export const FILE_DOWNLOAD_ERROR_MESSAGE = 'Failed to Download'
export const DELIVERY = 'delivery'
export const KPI = 'kpi'
export const TARGET = 'target'
export const KPI_GOAL = 'kpi_goal'
export const KPI_TARGET = 'kpi_target'
export const KPI_UNIT = 'kpi_unit'
export const PERCENTAGE = 'percentage'
export const CURRENCY_TYPE = 'currency_type'
export const NA = 'NA'
export const LOWER_CASE_ERROR = 'error'
export const CPA = 'cpa'
export const CPM = 'cpm'
export const CTR = 'ctr'
export const CPC = 'cpc'
export const VIEWABILITY_RATE = 'viewability_rate'
export const UPLOAD = 'Upload'
export const EXCLUSION_LIST = 'Exclusion List'
export const CUSTOM_BIDDING_MODEL = 'Custom Bidding Model'
export const AWAITING_REVIEW = 'awaiting review'
export const SUCCESS_LOWER_CASE = 'success'
export const TRADER = 'trader'
export const ADMIN = 'admin'
export const UNKNOWN_USER = 'Unknown User'
export const SELECT_PARTNER = 'Please select Partner'
export const SELECT_ADVERTISER = 'Please select Advertiser'
export const SELECT_CAMPAIGN = 'Please select Campaign'
export const SELECT_IO = 'Please select IOs'
export const SELECT_LINE_ITEMS = 'Please select Line Items'
export const LINKED_ACCOUNTS_TITLE = 'Linked Accounts'
export const LINKED_ACCOUNTS_DESCRIPTION =
  'To enable access to a linked account, Provision read/write access to svc_prog_optimise@dentsu.com'
export const ERROR_MESSAGE = 'Request failed with status code 403'
export const DV360_CUSTOM_BIDDING_ID = 'DV360 Custom Bidding ID'
export const DV360_CHANNEL_ID = 'DV360 Channel ID'
export const VCR = 'vcr'
export const CPCV = 'cpcv'
export const VTR = 'VTR'
export const AS = 'Attention_Score'
export const CPAU = "Cost_Per_AU"
export const CHANNEL_LIST= "Download Channel List"
export const DOWNLOAD_INSIGHT ="Download Insights"
export const CUSTOM_BIDDING_SCRIPT= " Custom Bidding Script"
export const SELECT_GOALS = "Please select at least one goal apart from delivery KPI"
export const NEXT_SCHEDULE_DATE= "The next campaign optmisation is scheduled on"
export const RUN_NOW=" Run now"
export const EDIT_SPEND_TEERING_SETTINGS= "Edit spend steering settings"
export const INTERVAL_VALUE_VALIDATION="Insertion Order cannot be scheduled for less than 7 days"
export const PAGE_DESCRIPTION = "Deals Manager enables teams to create and manage auction packages and direct publisher deals. Data directly integrated with SSPs can also be applied."
export const NOIOFOUND = "No relevant IOs found."
export const DEAL = 'Create deal'
export const CAMPAIGN_GOAL = "Provide the campaign goals for the optimisation script to tune performance for the campaign"
export const TOOLTIPCONTENTSMALL = "Seat ID is sometimes called Partner ID or Account ID within your DSP."
export const EXCHANGENAME="Exchange name"
export const MAKESELECTION = "Please make a selection"
export const DEALNAME = "Deal name"
export const ENTERDEALNAME ="Enter a name for your deal"
export const DEALTYPE = "Deal type"
export const DSPNAME = "DSP name"
export const DSP_SEAT_ID = "Enter DSP seat ID"
export const DSP_MESSAGE = "Ensure that the DSP Seat ID has been entered correctly"
export const BIDDING_STRATEGY= "Bidding strategy type"
export const FIRST_PRICE= "First price type"
export const FIXED_PRICE= "Fixed price type"
export const SECOND_PRICE="Standard price type"
export const ASK_PRICE= "Ask price value"
export const PUBLISHER_NAME = "Publisher name"
export const SITES = "Domains"
export const DSP_ID_TOOLTIP_CONTENT = 'While you can proceed with building a deal, the DSP Seat ID you have entered does not match our tracked list of dentsu or client owned seats. Please double check the seat entry and, if correct, reach out to dmx-support@dentsu.com to have the seat updated in our tracked list of seats.'
export const MARKETNAME="Market"
export const DEALNAMETOOLTIP = "Use unique identifiers like e.g. client name, inventory dimensions, to differentiate your deals from each other"
export const SUCCESS_DEACTIVATE_DEAL = 'Your deal has been Deactivated successfully'
export const FAILURE_DEACTIVATE_DEAL = 'Deal has not been Deactivated'
export const CANCEL_BUTTON_NAME = 'No,dont cancel'
export const POSITIVE_BUTTON_NAME = 'Deal has not been Deactivated'
export const SUCCESS_CREATE_DEAL = 'Your deal has been Created successfully.'
export const SUCCESS_EDIT_DEAL = 'Your deal has been updated successfully'
export const DOMAINTOOLTIP =""
export const DSPSEATIDTOOLTIP = "Seat ID is sometimes called Partner ID or Account ID within your DSP."
export const IABTOOLTIP = "A domain's IAB content category is determined by its content.Selecting a content category will filter your deal to those domains that have been categorised under that content category."
export const CORELOCALTOOLTIP = "Each local market has indicated its list of core investment publishers.Selecting one of these will focus spend on it."
export const PREFIXEDDEALNAME = 'Prefixed deal name'
export const PREFIXEDDEALNAMETOOLTIP = 'This is what your Deal Name will look like on the Deals listing page.'
export const GEOTARGET="Geo-target"
export const DEALTYPES= "Deal Type"
export const DEACTIVATECANCELBUTTON = "No, don't deactivate"
export const DASHBOARD_CONTENT="Optimisation tools enable clients and teams to automate campaign insights and optimisation process’ through the application of proprietary data science, algorithms and data processing techniques."
export const ATTENTION_ECONOMY = 'The Attention Economy'
export const GOVERNANCE_PAGE_HEADER = 'Governance tools provide a watchful eye on campaign events, ensuring best practice is applied with every keystroke.'
export const OPTIMISATION_PAGE_HEADER = 'Optimisation tools enable clients and teams to automate campaign insights and optimisation process’ through the application of proprietary data science, algorithms and data processing techniques.'
export const CAMPAIGNSETUP_PAGE_HEADER = 'Campaign setup and planning tools enable access to a range of data, tools, algorithms and process’ that enable more effective campaign planning and setup.'
export const MEASUREMENT_PAGE_HEADER = 'Measurement tools enable a range of reporting and measurement functionality from activation platforms. From ensuring data integrity to streamlined data workflows, measurement tools ensure we deliver consistent, accurate and up-to-date data to clients and teams.'
export const DATASOURCES_PAGE_DESCRIPTION = 'Data sources are domain listing repositories that Supply Managers can tap into to create trusted inventory pools used when setting up packages.'
export const NO_DATE_VALIDATE = 'Please select start and end date'
export const INVENTORY_PAGE_DECRIPTION='Inventory lists are curated, high quality and trusted inventory pools indexed against media metrics, synced to different platforms to ensure maximum inventory coverage and effectiveness.'
export const globalDataSourceInfo = 'This is a global data pre-filter and has been created in the ‘All Data Sources’ tab.'
export const INVENTORYDESCRIPTION = "Use a name for your inventory list that contains descriptors e.g. data source, SSP, client, or market that best fits its use case"
export const INVENTORYLISTNAME = "Inventory list name"
export const globalRuleDataSourceInfo = 'This is a global rule and has been created in the ‘All Data Sources’ tab. '
export const INVENTORYBUTTON = " No do not delete"
export const failedRuleInfo = 'This rule wasn’t applied successfully. Please check the differentrule parameters and try applying the rule again.'
export const BrandAssurance = 'DBA (Global)'
export const MEDIAVERIFICATIONCONTENT = 'Select media verification data sources applicable to your use case, from the following options'
export const SSPDATACONTENT = 'Select one SSP data source from the following options'
export const DENTSUBRANDASSURANCE = 'Select Dentsu media verification data sources from the following options'
export const MEASUREMENTBDA = 'Domain lists for various markets curated and verified by geographic-specific Dentsu Brand Assurance teams'
export const TOOLTIPEXTERNALMEDIA = 'Domain lists verified and curated by external (non-Dentsu) media verification sources'
export const DS_TOOLTIP_CONTENT='Domain lists exported from Supply-side Platforms that you can to use to build your inventory list'
export const BROADMATCH='Matches the URLs and app bundle IDs that contain a set of characters you specify for targeting. This set of characters is called a substring. Broad Match is ideal for targeting entire sites, apps or keywords that appear in page URLs/app bundle IDs.'
export const SIMPLEMATCH='Looks for the URLs, app bundle IDs or app IDs you specify in deal targeting. The traffic must be from the exact pages, apps specified but this match type ignores query parameters, hash or anchor tags,protocol and trailing slashes, and www in the URL'
export const EXACTMATCH='Looks only for URLs, app bundle IDs, or app IDs that are exactly what you specify in deal targeting'
export const LISTTYPE = "List type"
export const LISTSELECTION = "List selections"
export const REVERT_SCRIPT = 'Revert Script'
export const DOMAINSELECTION = "Domain selection"
export const DURATION = "Deal duration"
export const INCLUSION_LIST_ACTIVE = "Only selections that you make will be <b>INCLUDED</b> in the geo-targeting process.If no selections are made, <b>ALL</b> geographies will be included."
export const GEORESETBUTTON='No, don\'t reset'
export const INVWARNING = "You will not be able to proceed to the next step unless you include domains in your inventory list."
export const AUDIENCETYPE = "Audience type"
export const AUDIENCESEGMENT = 'Audience segment'
export const USER_MGMT_DESCRIPTION = 'Here you can manage access to role, partners, applications and campaign setups for Supply, Deals and Performance applications.'

