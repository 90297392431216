import styled from '@emotion/styled'
import { Box, Icon } from '@dentsu-ui/components'

export const StyledLayout = styled(Box)`
  width: 100%;
  height: 60px;
  display: flex;
  background: var(--dentsu-color-n1000);
`
export const StyledLeftContainer = styled(Box)`
  flex: 70%;
  display: flex;
  justify-content: flex-start;
`
export const StyledHomeOutside = styled(Box)`
  position: absolute;
  background: var(--dentsu-color-b500);
  width: 60px;
  height: 60px;
`
export const StyledHomeInside = styled(Box)`
  position: absolute;
  inset: 33.33%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: #FFFFFF;
`
export const StyledHeading = styled(Box)`
  position: absolute;
  left: 90px;
  top: 20px;
  font-family: Inter;
  color: #FFFFFF;
  font-size: var(--dentsu-font-weight-medium);
  font-weight: var(--dentsu-font-weight-medium);
  line-height: 20px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
`
export const StyledRightContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  margin-top: 10px;
`
export const StyledAvatar = styled(Box)`
  position: absolute;
  top: 10px;
  right: 42px;
`
export const StyledArrowDownIcon = styled(Box)`
  position: absolute;
  top: 20px;
  right: 26px;
  cursor: pointer;
  color: #FFFFFF;
`
export const StyledPopoverHover = styled(Box)`
  &:hover {
    cursor: pointer;
    background: var(--dentsu-color-n100);
  }
`
export const StyledLogoutIcon = styled(Icon)`
  margin-bottom: 2px;
  margin-right: 8px;
`
