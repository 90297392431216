import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import services from '../../../services/services'
import { processOktaUserList } from './helper'
import { getHeaders } from '../../../common/helper'

export const getOktaUsers = createAsyncThunk(
  'get/getOktaUsers',
  async ({ oktaUserEmailId, oktaUserId, accessToken }) => {
    const res = await services.getOktaUsers(getHeaders(oktaUserId, accessToken))
    return processOktaUserList(res.data.data, oktaUserEmailId)
  }
)

export const updateUserDetails = createAsyncThunk(
  'post/updateUserDetails',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await services.updateUserDetails(
      data,
      getHeaders(oktaUserId, accessToken)
    )
    return res.data
  }
)

export const getSelectedDentsuUser = createAsyncThunk(
  'get/getSelectedDentsuUser',
  async ({ oktaUserId, accessToken }) => {
    const res = await services.getSelectedDentsuUser(
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data[0]
  }
)

export const getUserDetails = createAsyncThunk(
  'get/getUserDetails',
  async ({ oktaUserId, accessToken }) => {
    const res = await services.getSelectedDentsuUser(
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data[0]
  }
)

export const getAllCards = createAsyncThunk(
  'get/allCards',
  async ({ oktaUserId, accessToken }) => {
    const res = await services.getAllCards(
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data
  }
)

const initialState = {
  oktaUsers: [],
  dentsuUsers: {},
  cards: []
}

const resetState = {
  oktaUsers: [],
  dentsuUsers: {},
  cards: []
}

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState: initialState,
  reducers: {
    reset: state => ({ ...state, ...resetState })
  },
  extraReducers: {
    [getOktaUsers.pending]: state => {
      state.loadingOktaUsers = true
      state.oktaUsers = []
    },
    [getOktaUsers.fulfilled]: (state, action) => {
      state.loadingOktaUsers = false
      state.oktaUsers = action.payload
    },
    [updateUserDetails.pending]: state => {
      state.updatingUserDetails = true
      state.updateUserDetails = {}
    },
    [updateUserDetails.rejected]: (state, action) => {
      state.updatingUserDetails = false
      state.updateUserDetails = action.payload
    },
    [updateUserDetails.fulfilled]: (state, action) => {
      state.updatingUserDetails = false
      state.updateUserDetails = action.payload
    },
    [getAllCards.pending]: state => {
      state.cards = true
      state.cards = []
    },
    [getAllCards.rejected]: (state, action) => {
      state.cards = false
      state.cards = action.payload
    },
    [getAllCards.fulfilled]: (state, action) => {
      state.cards = false
      state.cards = action.payload
    }
  }
})

export const { reset } = userManagementSlice.actions

export default userManagementSlice.reducer
