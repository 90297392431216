import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import services from '../../../services/services'
import { getHeaders } from '../../../common/helper'

export const getGovernanceData = createAsyncThunk(
  'get/cards',
  async ({ page, oktaUserId, accessToken }) => {
    const res = await services.getCardsData(
      page,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)
export const postEmail = createAsyncThunk(
  'post/governance/email',
  async ({data, accessToken, oktaUserId }) => {
    const res = await services.sendEmail(
      data,
      getHeaders(oktaUserId,accessToken)
    )
    return res?.data
  }
)

const initialState = {
  governanceData: [],
  postEmail:[]
}

const resetState = {
  governanceData: [],
  postEmail:[]

}

const governanceDataSlice = createSlice({
  name: 'governanceData',
  initialState: initialState,
  reducers: {
    reset: state => ({ ...state, ...resetState })
  },
  extraReducers: {
    [getGovernanceData.pending]: state => {
      state.loading = true
      state.governanceData = []
    },
    [getGovernanceData.rejected]: (state, action) => {
      state.loading = false
      state.governanceData = []
      state.rejected = action
    },
    [getGovernanceData.fulfilled]: (state, action) => {
      state.loading = false
      state.governanceData = action.payload
    },
    [postEmail.pending]: state => {
      state.loading = true
      state.postEmail = []
    },
    [postEmail.rejected]: (state, action) => {
      state.loading = false
      state.postEmail = []
      state.rejected = action
    },
    [postEmail.fulfilled]: (state, action) => {
      state.loading = false
      state.postEmail = action.payload
    }
  }
})

export const { reset } = governanceDataSlice.actions

export default governanceDataSlice.reducer
