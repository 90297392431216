import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import services from '../../../services/services'
import { getHeaders } from '../../../common/helper'

export const getCampaignSetupData = createAsyncThunk(
  'get/cards',
  async ({ page, oktaUserId, accessToken }) => {
    const res = await services.getCardsData(
      page,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)
export const postEmail = createAsyncThunk(
  'post/campaignSetup/email',
  async ({data, accessToken, oktaUserId }) => {
    const res = await services.sendEmail(
      data,
      getHeaders(oktaUserId,accessToken)
    )
    return res?.data
  }
)

const initialState = {
  campaignSetupData: [],
}

const resetState = {
  campaignSetupData: [],
}

const campaignSetupData = createSlice({
  name: 'campaignSetupData',
  initialState: initialState,
  reducers: {
    reset: state => ({ ...state, ...resetState })
  },
  extraReducers: {
    [getCampaignSetupData.pending]: state => {
      state.loading = true
      state.campaignSetupData = []
    },
    [getCampaignSetupData.rejected]: (state, action) => {
      state.loading = false
      state.campaignSetupData = []
      state.rejected = action
    },
    [getCampaignSetupData.fulfilled]: (state, action) => {
      state.loading = false
      state.campaignSetupData = action.payload
    },
  }
})

export const { reset } = campaignSetupData.actions

export default campaignSetupData.reducer
