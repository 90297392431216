import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import services from '../../../services/services'
import {
  programmaticChooserData,
  preparePartnersData,
  prepareKPIOptionsData,
  prepareAdvertiserData,
  prepareCampaignData,
  prepareLineItemData,
  prepareIoData,
  prepareOptimisationPerformanceData,
  prepareOptimisationHistory,
  prepareStatus,
  prepareIOOptionsData,
  saveToken,
  saveCampaignToken,
  saveIOToken,
  saveLIToken,
  prepareVerifyIoData,
  prepareExchangeData,
  countDeal,
} from './helper'
import {
  prepareOptionsData,
  getHeaders,
  prepareOptionsDataIO,
  getHeadersToken
} from '../../../common/helper'

export const getPartners = createAsyncThunk(
  'get/getPartners',
  async ({ oktaUserId, accessToken}) => {
    const res = await services.getPartners(getHeaders(oktaUserId, accessToken))
    return res?.data
  }
)

export const getAllPartnersData = createAsyncThunk(
  'get/getALLPartnersData',
  async ({ id, oktaUserId, accessToken }) => {
    const res = await services.getAllPartnersData(
      id,
      getHeaders(oktaUserId, accessToken)
    )
    const statusValue = prepareStatus(res.data.data)
    return preparePartnersData(statusValue, id)
  }
)

export const optimisationTriggered = createAsyncThunk(
  'post/optimisationTriggered',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await services.optimisationTriggered(
      data,
      getHeaders(oktaUserId, accessToken)
    )
    return await res.data.data
  }
)

export const deactivateCampaign = createAsyncThunk(
  'get/deactivateCampaign',
  async ({ id, oktaUserId, accessToken }) => {
    const res = await services.deactivateCampaign(
      id,
      getHeaders(oktaUserId, accessToken)
    )
    return await res.data.data
  }
)

export const getOptimizationType = createAsyncThunk(
  'get/optimisations-selection',
  async ({ oktaUserId, accessToken }) => {
    const res = await services.getOptimizationType(
      getHeaders(oktaUserId, accessToken)
    ) 
    countDeal(res.data.deal_count)
    return await programmaticChooserData(res.data.data, 'optimizationTypes')
    
  }
)

export const getAdevertisers = createAsyncThunk(
  'get/getAdvertisers',
  async ({ id, oktaUserId, accessToken, pageToken }) => {
    let res
    if (!pageToken) {
      res = await services.getAdevertisers(
        id,
        getHeaders(oktaUserId, accessToken)
      )
    } else {
      res = await services.getAdevertisers(
        id,
        getHeadersToken(oktaUserId, accessToken, pageToken)
      )
    }

    prepareAdvertiserData(res?.data?.advertisers)
    const result = res?.data?.nextPageToken ? res?.data : ''
    saveToken(result)

    return prepareOptionsData(res?.data?.advertisers, 'advertiserId')
  }
)
export const getExchanges=createAsyncThunk(
  'get/getExchanges',
  async({id,oktaUserId,accessToken})=>{
    const res = await services.getExchanges(id,
      getHeaders(oktaUserId, accessToken)
    )

    prepareExchangeData(res?.data?.exchanges)
    return prepareOptionsData(res?.data?.exchanges, 'exchanges')
    
  }

)
export const getCampaigns = createAsyncThunk(
  'get/getCampaigns',
  async ({ id, oktaUserId, accessToken, pageToken }) => {
    let res
    if (!pageToken) {
      res = await services.getCampaigns(id, getHeaders(oktaUserId, accessToken))
    } else {
      res = await services.getCampaigns(
        id,
        getHeadersToken(oktaUserId, accessToken, pageToken)
      )
    }
    prepareCampaignData(res?.data?.campaigns)
    const result = res?.data?.nextPageToken ? res?.data : ''
    saveCampaignToken(result)
    return prepareOptionsData(res?.data?.campaigns, 'campaignId')
  }
)
export const getVerifiIo = createAsyncThunk(
  'get/getVerifyIo',
  async ({ ioId, advertiserId, campaignId, existing_io_type, existing_io_id, oktaUserId, accessToken }) => {
    const res = await services.getVerifyIo(ioId, advertiserId, campaignId, existing_io_type, existing_io_id, getHeaders(oktaUserId, accessToken))
    prepareVerifyIoData(res?.data)
    return res?.data
  }
  )
export const getLineItems = createAsyncThunk(
  'get/getLineItems',
  async ({ id, advertiserId, oktaUserId, accessToken, pageToken }) => {
    let res
    if (!pageToken) {
      res = await services.getLineItems(id, advertiserId, getHeaders(oktaUserId, accessToken))
    } else {
      res = await services.getLineItems(
        id, advertiserId, getHeadersToken(oktaUserId, accessToken, pageToken)
      )
    }
    prepareLineItemData(res?.data?.lineItems)
    const result = res?.data.nextPageToken ? res.data : ''
    saveLIToken(result)
    return prepareOptionsData(res?.data.lineItems, "lineItemId")
  }
)

export const getIOs = createAsyncThunk(
  'get/insertion_order',
  async ({ advertiserId, campaignId, oktaUserId, accessToken, pageToken }) => {
    let res
    if (!pageToken) {
      res = await services.getIOs(
        advertiserId,
        campaignId,
        getHeaders(oktaUserId, accessToken)
      )
    } else {
      res = await services.getIOs(
        advertiserId,
        campaignId,
        getHeadersToken(oktaUserId, accessToken, pageToken)
      )
    }
    prepareIoData(res?.data?.insertionOrders)
    const checkReservationType = prepareIOOptionsData(
      res?.data?.insertionOrders,
      'insertionOrderId'
    )
    const result = res?.data?.nextPageToken ? res?.data : ''
    saveIOToken(result)
    return prepareOptionsDataIO(checkReservationType, 'insertionOrderId')
  }
)

export const getKPIs = createAsyncThunk(
  'get/kpi',
  async ({ id1,id2,oktaUserId, accessToken }) => {
    const res = await services.getKPIs(id1,id2,getHeaders(oktaUserId, accessToken))
    return await prepareKPIOptionsData(res.data.data)
  }
)

export const postOptimization = createAsyncThunk(
  'post/optimisations',
  async ({ data, oktaUserId, accessToken }) => {
    const res = await services.postOptimization(
      data,
      getHeaders(oktaUserId, accessToken)
    )
    return await res.data
  }
)
export const getOptimisationPerformance = createAsyncThunk(
  'get/OptimisationPerformance',
  async ({ id, oktaUserId, accessToken }) => {
    const res = await services.getOptimisationPerformance(
      id,
      getHeaders(oktaUserId, accessToken)
    )
    return await prepareOptimisationPerformanceData(res.data.data, id)
  }
)
export const getOptimisationHistory = createAsyncThunk(
  'get/OptimisationHistory',
  async ({ id, oktaUserId, accessToken }) => {
    const res = await services.getOptimisationHistory(
      id,
      getHeaders(oktaUserId, accessToken)
    )
    return await prepareOptimisationHistory(res.data.data, id)
  }
)
export const getDownloadInsights = createAsyncThunk(
  'get/DownloadInsights',
  async ({ id, oktaUserId, accessToken }) => {
    const res = await services.getDownloadInsights(
      id,
      getHeaders(oktaUserId, accessToken)
    )
    return await res.data
  }
)
export const postKpiGoals = createAsyncThunk(
  'post/kpi',
  async ({ id, data, oktaUserId, accessToken }) => {
    const res = await services.postKpiGoalsData(
      id,
      data,
      getHeaders(oktaUserId, accessToken)
    )
    return await res.data
  }
)
export const getCampaignSettings = createAsyncThunk(
  'get/optimisations',
  async ({ configIdValue, oktaUserId, accessToken }) => {
    const res = await services.getCampaignSettings(
      configIdValue,
      getHeaders(oktaUserId, accessToken)
    )
    return await res.data.data
  }
)
export const updateCampaignSettings = createAsyncThunk(
  'put/optimisations',
  async ({ id, data, oktaUserId, accessToken }) => {
    const res = await services.updateCampaignSettings(
      data,
      id,
      getHeaders(oktaUserId, accessToken)
    )
    return await res.data
  }
)
export const fileUpload = createAsyncThunk(
  'post/uploadDetails',
  async ({ dataDetails: data, accessToken, oktaUserId }) => {
    const res = await services.fileUpload(
      data.formData,
      data.value,
      getHeaders(oktaUserId, accessToken)
    )
    return res.data
  }
)

export const fileUploadSteerling = createAsyncThunk(
  'post/steering_upload',
  async ({dataDetails, id, accessToken, oktaUserId }) => {
    const res = await services.fileUploadSteerling(
      dataDetails.formData,
      id,
      getHeaders(oktaUserId,accessToken)
    )
    return res.data
  }
)

export const postSteeringModalData = createAsyncThunk(
  'post/save_steering',
  async ({data, accessToken, oktaUserId }) => {
    const res = await services.postSteeringModalData(
      data,
      getHeaders(oktaUserId,accessToken)
    )
    return res.data
  }
)

export const linkedpartneraccount = createAsyncThunk(
  'post/link_partner_account',
  async ({req_data, oktaUserId,accessToken }) => {
    const res = await services.linkedpartneraccount(
      req_data,
      getHeaders(oktaUserId,accessToken)
    )
    return res.data
  }
)

export const getlinkedpartneraccount = createAsyncThunk(
  'get/link_partner_account',
  async ({optimisation_type_id, partner_id, oktaUserId,accessToken }) => {
    const res = await services.getlinkedpartneraccount(
      optimisation_type_id,
      partner_id,
      getHeaders(oktaUserId,accessToken)
    )
    return res.data
  }
)
export const getLinkedPartnerAccountIas = createAsyncThunk(
  'get/link_partner_account',
  async ({oktaUserId,accessToken }) => {
    const res = await services.getLinkedPartnerAccountIas(
      getHeaders(oktaUserId,accessToken)
    )
    return res.data
  }
)

export const getIasCampaignDetails = createAsyncThunk(
  'get/get_ias_campaign',
  async ({ partner_account_teamId, accessToken, oktaUserId }) => {
    const res = await services.getIasCampaignDetails(
      partner_account_teamId,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

export const getScriptVersion = createAsyncThunk(
  'get/optimisation-script-version',
  async ({ oc_id, accessToken, oktaUserId }) => {
    const res = await services.getScriptVersion(
      oc_id,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

export const postScriptVersion = createAsyncThunk(
  'post/script-version',
  async ({ data, oc_id, accessToken, oktaUserId }) => {
    const res = await services.postScriptVersion(
      data,
      oc_id,
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

const initialState = {
  partners: [],
  advertisers: [],
  campaigns: [],
  ios: [],
  lineItems:[],
  deactivateCampaignStatusData: [],
  postOptimizationsDetails: [],
  optimizationTypes: [],
  KPIs: [],
  editCampaignSettings: [],
  updateCampaignSettings: [],
  fileUpload: [],
  verifyIo:[],
  fileUploadSteerling:[],
  exchanges:[],
  steeringModalData:[],
  linkedpartneraccountData:[],
  getlinkedpartneraccount : [],
  getLinkedPartnerAccountias :[],
  IasCampaignDetails: [],
  scriptVersion : []
}

const resetState = {
  advertisers: [],
  campaigns: [],
  ios: [],
  lineItems :[],
  optimizationTypes: [],
  KPIs: [],
  editCampaignSettings: [],
  fileUpload: [],
  verifyIo: [],
  fileUploadSteerling:[],
  exchanges :[],
  steeringModalData:[],
  linkedpartneraccountData:[],
  getlinkedpartneraccount : [],
  getLinkedPartnerAccountias :[],
  scriptVersion : []
}

const campaignOptimizationSlice = createSlice({
  name: 'campaignOptimization',
  initialState: initialState,
  reducers: {
    reset: state => ({ ...state, ...resetState })
  },
  extraReducers: {
    [getPartners.pending]: state => {
      state.loading = true
      state.loadingPartners = true
      state.partners = []
    },
    [getPartners.rejected]: (state, action) => {
      state.loading = false
      state.loadingPartners = false
      state.partners = []
      state.rejected = action
    },
    [getPartners.fulfilled]: (state, action) => {
      state.loading = false
      state.loadingPartners = false
      state.partners = action.payload
    },
    [getAdevertisers.pending]: state => {
      state.loading = true
      state.advertisers = []
    },
    [getAdevertisers.fulfilled]: (state, action) => {
      state.loading = false
      state.advertisers = action.payload
    },
    [getAdevertisers.rejected]: (state, action) => {
      state.loading = false
      state.rejected = action
    },
    [getExchanges.pending]: state => {
      state.loading = true
      state.exchanges = []
    },
    [getExchanges.fulfilled]: (state, action) => {
      state.loading = false
      state.exchanges = action.payload
    },
    [getExchanges.rejected]: (state, action) => {
      state.loading = false
      state.rejected = action
    },
    [getCampaigns.pending]: state => {
      state.loading = true
      state.campaignLoading = true
      state.campaigns = []
    },
    [getCampaigns.rejected]: (state, action) => {
      state.loading = false
      state.campaignLoading = false
      state.rejected = action
    },
    [getCampaigns.fulfilled]: (state, action) => {
      state.loading = false
      state.campaignLoading = false
      state.campaigns = action.payload
    },
    [getLineItems.pending]: state => {
      state.loading = true
      state.lineItemLoading = true
      state.lineItems = []
    },
    [getLineItems.rejected]: (state, action) => {
      state.loading = false
      state.lineItemLoading = false
      state.rejected = action
    },
    [getLineItems.fulfilled]: (state, action) => {
      state.loading = false
      state.lineItemLoading = false
      state.lineItems = action.payload
    },
    [getVerifiIo.pending]: state => {
      state.loading = true
      state.verifyIo = []
    },
    [getVerifiIo.fulfilled]: (state, action) => {
      state.loading = false
      state.verifyIo = action.payload
    },
    [getIOs.pending]: state => {
      state.loading = true
      state.ioLoad = true
      state.ios = []
    },
    [getIOs.rejected]: (state, action) => {
      state.loading = false
      state.ioLoad = false
      state.rejected = action
    },
    [getIOs.fulfilled]: (state, action) => {
      state.loading = false
      state.ioLoad = false
      state.ios = action.payload
    },
    [getOptimizationType.pending]: state => {
      state.optimizationTypesloading = true
      state.optimizationTypes = []
    },
    [getOptimizationType.fulfilled]: (state, action) => {
      state.optimizationTypesloading = false
      state.optimizationTypes = action.payload
    },
    [getAllPartnersData.pending]: state => {
      state.partnersDataLoading = true
      state.partnersData = []
    },
    [getAllPartnersData.fulfilled]: (state, action) => {
      state.partnersDataLoading = false
      state.partnersData = action.payload
    },
    [deactivateCampaign.pending]: state => {
      state.loading = true
      state.deactivateCampaignStatusData = []
    },
    [deactivateCampaign.fulfilled]: (state, action) => {
      state.loading = false
      state.deactivateCampaignStatusData = action.payload
    },
    [postOptimization.pending]: state => {
      state.loading = true
      state.postOptimizationsDetails = []
    },
    [postOptimization.fulfilled]: (state, action) => {
      state.loading = false
      state.postOptimizationsDetails = action.payload
    },
    [optimisationTriggered.pending]: state => {
      state.loading = true
      state.optimisationTriggeredDetails = []
    },
    [optimisationTriggered.fulfilled]: (state, action) => {
      state.loading = false
      state.optimisationTriggeredDetails = action.payload
    },
    [getKPIs.pending]: state => {
      state.loading = true
      state.goalOptions = []
    },
    [getKPIs.fulfilled]: (state, action) => {
      state.loading = false
      state.goalOptions = action.payload
    },
    [getOptimisationPerformance.pending]: state => {
      state.optimisationPerformanceLoading = true
      state.optimisationPerformance = []
    },
    [getOptimisationPerformance.fulfilled]: (state, action) => {
      state.optimisationPerformanceLoading = false
      state.optimisationPerformance = action.payload
    },
    [getOptimisationHistory.pending]: state => {
      state.loading = true
      state.optimisationHistory = []
    },
    [getOptimisationHistory.fulfilled]: (state, action) => {
      state.loading = false
      state.optimisationHistory = action.payload
    },
    [getDownloadInsights.pending]: state => {
      state.loading = true
      state.downloadInsights = []
    },
    [getDownloadInsights.fulfilled]: (state, action) => {
      state.loading = false
      state.downloadInsights = action.payload
    },
    [postKpiGoals.fulfilled]: (state, action) => {
      state.loading = false
      state.optimisationPerformance.kpi = action.payload.data.kpi
    },
    [getCampaignSettings.pending]: state => {
      state.loading = true
      state.editCampaignSettings = []
    },
    [getCampaignSettings.fulfilled]: (state, action) => {
      state.loading = false
      state.editCampaignSettings = action.payload
    },
    [updateCampaignSettings.pending]: state => {
      state.loading = true
      state.updateCampaignSettings = []
    },
    [updateCampaignSettings.fulfilled]: (state, action) => {
      state.loading = false
      state.updateCampaignSettings = action.payload
    },
    [fileUpload.pending]: state => {
      state.fileUpload = []
    },
    [fileUpload.fulfilled]: (state, action) => {
      state.fileUpload = action.payload
    },
    [fileUploadSteerling.pending]: state => {
      state.fileUploadSteerling = []
    },
    [fileUploadSteerling.fulfilled]: (state, action) => {
      state.fileUploadSteerling = action.payload
    },
    [postSteeringModalData.pending]: state => {
      state.steeringModalData = []
    },
    [postSteeringModalData.fulfilled]: (state, action) => {
      state.steeringModalData = action.payload
    },
    [linkedpartneraccount.pending]: state => {
      state.linkedpartneraccount = []
    },
    [linkedpartneraccount.fulfilled]: (state, action) => {
      state.linkedpartneraccount = action.payload
    },
    [getlinkedpartneraccount.pending]: state => {
      state.getlinkedpartneraccount = []
    },
    [getlinkedpartneraccount.fulfilled]: (state, action) => {
      state.getlinkedpartneraccount = action.payload
    },
    [getLinkedPartnerAccountIas.pending]: state => {
      state.getLinkedPartnerAccountias = []
    },
    [getLinkedPartnerAccountIas.fulfilled]: (state, action) => {
      state.getLinkedPartnerAccountias = action.payload
    },
    [getIasCampaignDetails.fulfilled]: (state, action) => {
      state.IasCampaignDetails = action.payload
    },
    [getIasCampaignDetails.pending]: state => {
      state.IasCampaignDetails = []
    },
    [getScriptVersion.fulfilled]: (state, action) => {
      state.scriptVersion = action.payload
    },
    [getScriptVersion.pending]: state => {
      state.scriptVersion = []
    },
    
  }
})

export const { reset } = campaignOptimizationSlice.actions

export default campaignOptimizationSlice.reducer