export const getHeaders = (token, headers) => {
  let headersData = {}
  if (token)
    headersData = {
      'page-token': token
    }

  if (headers) {
    headersData = { ...headersData, ...headers }
  }

  return headersData
}

export const getHistoryHeaders = (id, headers) => {
  let reqHeaders = {
    optimisation_config_id: id
  }

  if (headers)
    reqHeaders = {
      ...reqHeaders,
      ...headers
    }

  return reqHeaders
}
