import isEmpty from 'lodash/isEmpty'
import { ADMIN } from '../../../../constants'

/**
 * @function prepareUserDetails called to set user details when logged in to the application
 * @param {object} userDetails contains user details
 * @returns {object} contains user role details
 *
 */
export const prepareUserDetails = userDetails => {
  if (isEmpty(userDetails)) return { isDentsuUser: false }

  if (userDetails?.partner?.length) {
    userDetails = {
      ...userDetails,
      ...{ partnerIds: userDetails.partner.map(partner => partner.partner_id) }
    }
  }

  if (userDetails?.optimisation_type?.length) {
    userDetails = {
      ...userDetails,
      ...{
        otIds: userDetails.optimisation_type.map(
          type => type.id
        )
      }
    }
  }

  return {
    isDentsuUser: true,
    isAdminUser: userDetails.role === ADMIN,
    userInfo: userDetails
  }
}
