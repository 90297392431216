import http from './http-common'
import https from './http-commonSupply'
import { getHeaders, getHistoryHeaders } from './helper'
class Service {
  getOptimizationType(headers) {
    if (!headers) return http.get('/optimisations-selection')

    return http.get('/optimisations-selection', { headers: headers })
  }
  getPartners(headers, token) {
    return http.get('/partner', {
      headers: getHeaders(token, headers)
    })
  }
  getAdevertisers(partnerId, headers, token) {
    return http.get(`/advertiser?partner_id=${partnerId}`, {
      headers: getHeaders(token, headers)
    })
  }
  getExchanges(partnerId, headers, token) {
    return http.get(`/partner_exchanges?partner_id=${partnerId}`, {
      headers: getHeaders(token, headers)
    })
  }
  getCampaigns(advertiserId, headers, token) {
    return http.get(`/campaign?advertiser_id=${advertiserId}`, {
      headers: getHeaders(token, headers)
    })
  }
  getLineItems(ioId, advertiserId, headers, token) {
    return http.get(`/line_items?advertiser_id=${advertiserId}&insertion_order_id=${ioId}`,
      {
        headers: getHeaders(token, headers)
      })
  }
  getVerifyIo(ioId, advertiserId, campaignId, existing_io_type, existing_io_id, headers, token) {
    return http.get(`/verify_io?advertiser_id=${advertiserId}&campaign_id=${campaignId}&insertion_order_id=${ioId}&existing_io_type=${existing_io_type}&existing_io_id=${existing_io_id}`,
      {
        headers: getHeaders(token, headers)
      })
  }
  getIOs(advertiserId, campaignId, headers, token) {
    return http.get(
      `/insertion_order?advertiser_id=${advertiserId}&campaign_id=${campaignId}`,
      {
        headers: getHeaders(token, headers)
      }
    )
  }

  getAllPartnersData(id, headers) {
    return http.get('/optimisations', { headers: headers })
  }

  deactivateCampaign(id, headers) {
    const data = JSON.stringify({
      req_data: { status: 'De-active' }
    })
    if (!headers) return http.put(`/optimisation/status?oc_id=${id}`, data)

    return http.put(`/optimisation/status?oc_id=${id}`, data, {
      headers: headers
    })
  }
  getKPIs(id1, id2, headers) {
    if (id1 !== undefined && id2 === "") {
      return http.get(`/kpi?ost1=${id1}`, { headers: headers })
    }
    else {
      return http.get(`/kpi?ost1=${id1}&ost2=${id2}`, { headers: headers })
    }
  }
  postOptimization(requestData, headers) {
    return http.post('/optimisations', requestData, { headers: headers })
  }
  getOptimisationPerformance(id, headers) {
    return id && http.get(`/optimisation-performance?oc_id=${id}`, {
      headers: getHistoryHeaders(id, headers)
    })
  }
  getOptimisationHistory(id, headers) {
    return http.get(`/optimisation-history?oc_id=${id}`, {
      headers: getHistoryHeaders(id, headers)
    })
  }
  getDownloadInsights(id, headers) {
    return http.get(`/download/insights?run_id=${id}`, { headers: headers })
  }
  postKpiGoalsData(id, data, headers) {
    return http.put(`/kpi?oc_id=${id}`, data, { headers: headers })
  }
  getCampaignSettings(id, headers) {
    return http.get(`/optimisations?oc_id=${id}`, { headers: headers })
  }
  updateCampaignSettings(requestData, id, headers) {
    return http.put(`/optimisations?oc_id=${id}`, requestData, {
      headers: headers
    })
  }
  fileUpload(formData, value, headers) {
    return http.post(`/${value}`, formData, { headers: headers })
  }

  /** user management */
  getOktaUsers(headers) {
    return http.get('/list-users', { headers: headers })
  }

  updateUserDetails(requestData, headers) {
    return http.post('/save/user', requestData, { headers: headers })
  }
  optimisationTriggered(requestData, headers) {
    return http.post('/optimisation/trigger', requestData, { headers: headers })
  }

  getSelectedDentsuUser(headers) {
    return http.get('/okta-user', {
      method: "GET",
      mode: "cors",
      headers: headers
    })
  }
  getAllCards(headers) {
    return http.get('/cards', { headers: headers })
  }
  /** linked accounts */
  getLinkedAccount(headers) {
    return http.get('/linked-account', { headers: headers })
  }
  getCardsData(page, headers) {
    return http.get(`/user/cards?page=${page}`, { headers: headers })
  }
  fileUploadSteerling(formData, id, headers) {
    return http.post(`/steering_upload?partner_id=${id}`, formData, { headers: headers })
  }
  postSteeringModalData(data, headers) {
    return http.post('/save_steering', data, { headers: headers })
  }

  /** Email contact support */
  sendEmail(data, headers) {
    return http.post(`/governance/email`, data, { headers: headers })
  }

  linkedpartneraccount(req_data, headers) {
    return http.post(`/link_partner_account`, req_data, { headers: headers })
  }

  getlinkedpartneraccount(optimisation_type_id, partner_id, headers) {
    return http.get(`/link_partner_account?optimisation_type_id=${optimisation_type_id}&partner_id=${partner_id}`, { headers: headers })
  }
  getLinkedPartnerAccountIas(headers){
    return http.get(`/get_ias_team_name`, { headers: headers })

  }
  
  getIasCampaignDetails(partner_account_teamId, headers) {
    return http.get(`/get_ias_campaign?team_id=${partner_account_teamId}`, { headers: headers })
  }

  getScriptVersion(oc_id, headers) {
    return http.get(`/optimisation-script-version?oc_id=${oc_id}`, { headers: headers })
  }

  postScriptVersion(req_data, oc_id, headers) {
    return http.post(`/optimisation-script-version?oc_id=${oc_id}`, req_data, { headers: headers })
  }

  /** DSM */

  getAllDealData(headers) {
    return https.get('/deal-setup/deal/list', { headers: headers })
  }

  dealNameVerify(req_data, headers) {
    return https.post(`/deal-setup/deal-name/verify`, req_data, { headers: headers })
  }

  getDealBasicData(headers) {
      return https.get(`/deal-setup/basic/data`, { headers: headers })
  }

  getSSPDealBasicData(ssp_id, headers) {
      return https.get(`/deal-setup/basic/data?ssp_id=${ssp_id}`, { headers: headers })
  }

  getDealMarket(headers, token) {
    return https.get(`/deal-setup/market/list`,
      {
        headers: getHeaders(token, headers)
      })
  }

  postDealCreation(req_data, sspData, headers) {
    if (sspData?.ssp_name === 'Pubmatic') {
      return https.post(`/deal-setup/pubmatic/create/deal`, req_data, { headers: headers })
    } else {
      return https.post(`/deal-setup/create-deal`, req_data, { headers: headers })
    }
  }

  getDealData(id, deal_type, ssp_name, headers) {
    if (deal_type === 'Package Deal' && ssp_name !== 'Pubmatic') {
      return https.get(`/deal_setup/get_deal?id=${id}&deal_type=package_deal`, { headers: headers })
    } else if (ssp_name !== 'Pubmatic') {
      return https.get(`/deal_setup/get_deal?id=${id}`, { headers: headers })
    } else {
      return https.get(`/deal-setup/pubmatic/create/deal?id=${id}`, { headers: headers })
    }
  }

  deactivateDeal(data, id, sspName, headers) {
    if (sspName !== "Pubmatic") {
      return https.put(`/deal-setup/deal_li/status?id=${id}`, data, {
        headers: headers
      })
    } else {
      return https.put(`/deal-setup/pubmatic/deal/updateStatus?id=${id}`, data, {
        headers: headers
      })
    }
  }

  getPublisherList(req_data, headers) {
    return https.post(`/deal-setup/publisher_inventory_details`, req_data, { headers: headers })
  }

  postDomainList(req_data, headers) {
    return https.post(`/deal-setup/domain_inventory_details`, req_data, { headers: headers })
  }

  postIABContentCategoryList(req_data, headers) {
    return https.post(`/deal-setup/category_inventory_details`, req_data, { headers: headers })
  }

  putEditDealData(req_data, id, deal_id, exchange_name, headers) {
    if(exchange_name !== 'Pubmatic'){
      return https.put(`/deal-setup/edit_deal?id=${id}&deal_id=${deal_id}`, req_data, { headers: headers })
    } else {
      return https.put(`/deal-setup/pubmatic/create/deal?id=${id}`, req_data, { headers: headers })
    }
  }

  getDomainLuanguageList(headers) {
    return https.get(`/deal-setup/domain_language_inventory_details`, { headers: headers })
  }

  getCorePublisher(headers) {
    return https.get(`/deal-setup/local_publisher_inventory_details`, {
      headers: headers
    })
  }

  getPublisherDomainsList(req_data, deal_type, start_with, headers) {
    return https.post(`/deal-setup/publisher_domain_inventory_details?deal_type=${deal_type}&start_with=${start_with}`, req_data, {
      headers: headers
    })
  }

  getPublisherDomainsCount(req_data, deal_type, headers) {
    return https.post(`/deal-setup/publisher_domain_inventory_details?deal_type=${deal_type}`, req_data, {
      headers: headers
    })
  }

  getPublisherDomainSearchResult(req_data, search_key, headers) {
    return https.post(`/deal-setup/publisher_domain_inventory_details?search_key=${search_key}`, req_data, {
      headers: headers
    })
  }

  viewAllDomainSearch(req_data, searchKey, view_domain, headers) {
    return https.post(`/deal-setup/publisher_domain_inventory_details?search_key=${searchKey}&view_all=${view_domain}`, req_data, {
      headers: headers
    })
  }

  getVendorsSegments(ssp_name, category, headers) {
    if (ssp_name !== "Pubmatic") {
      return https.get(`/deal-setup/vendor_segment_details`, {
        headers: headers
      })
    } else {
      return https.get(`/deal-setup/pubmatic/audiences?category=${category}`, {
        headers: headers
      })
    }
  }

  postForeCastData(req_data, headers) {
    return https.post(`/deal-setup/forecast`, req_data, { headers: headers })
  }

  postCalculateCpm(req_data, headers) {
    return https.post(`/deal-setup/calculate-cpm`, req_data, { headers: headers })
  }

  getDspList(pageNumber, pageSize, searchValue, headers) {
    if (!searchValue) {
      return https.get(`/deal-setup/pubmatic/dsp-list?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
        headers: headers
      })
    } else {
      return https.get(`/deal-setup/pubmatic/dsp-list?pageNumber=${pageNumber}&pageSize=${pageSize}&filters=${searchValue}`, {
        headers: headers
      })
    }
  }

  getDspSeatIDs(pageNumber, pageSize, searchValue, dspId, headers) {
    if (!searchValue) {
      return https.get(`/deal-setup/pubmatic/buyer-list?buyerPageNumber=${pageNumber}&buyerPageSize=${pageSize}&dspId=${dspId}`, {
        headers: headers
      })
    } else {
      return https.get(`/deal-setup/pubmatic/buyer-list?buyerPageNumber=${pageNumber}&buyerPageSize=${pageSize}&filters=${searchValue}&dspId=${dspId}`, {
        headers: headers
      })
    }
  }

  getPubmaticGeolist(pageNumber, pageSize, name, geoLocation, searchInput, headers) {
    if (searchInput) {
      return https.get(`/deal-setup/pubmatic/geo-list?pageNumber=${pageNumber}&pageSize=${pageSize}&name=${searchInput}`, {
        headers: headers
      })
    } else if (geoLocation) {
      return https.get(`/deal-setup/pubmatic/geo-list?pageNumber=${pageNumber}&pageSize=${pageSize}&geo_level=${geoLocation}`, {
        headers: headers
      })
    } else {
      return https.get(`/deal-setup/pubmatic/geo-list?pageNumber=${pageNumber}&pageSize=${pageSize}`, {
        headers: headers
      })
    }
  }

  postTargetingData(data, headers) {
    return https.post(`/deal-setup/pubmatic/targeting`, data, { headers: headers })
  }

  getBrowsersList(headers) {
    return https.get(`/deal-setup/pubmatic/browser-list`, {
      headers: headers
    })
  }

}
export default new Service()
