import React from 'react'
import { Toast } from '@dentsu-ui/components'
import CustomEmptyState from '../client/components/Shared/CustomEmptyState'
import { SUCCESS, WARNING, ERROR, POSITIVE } from '../constants'

/**
 * @function prepareOptionsData called to create options data
 * @param {array} data contains partners data
 * @param {string} id id
 * @returns {array} returns modified data
 *
 */
export const prepareOptionsData = (data = [], id) => {
  return data.map(item => {
    return { value: item[id], label: item.displayName }
  })
}

/**
 * @function prepareOptionsDataIO called to create options data for IO
 * @param {array} data contains IO data
 * @param {string} id id
 * @returns {array} returns modified data
 *
 */
export const prepareOptionsDataIO = (data = [], id) => {
  return data.map(item => {
    return {
      value: item[id],
      label: item.displayName,
      isDisabled: item.isDisabled
    }
  })
}

/**
 * @function changeCampaignGoal called to change the key values of goal array
 * @param {array} data contains goal data
 * @returns {array} returns modified data
 *
 */
export const changeCampaignGoal = (data = []) => {
  return data.map(item => {
    return {
      kpi_target: item?.kpi?.value,
      kpi_goal: item.target
    }
  })
}

/**
 * @function callDate called to change the key values of goal array
 * @param {object} date contains day, month, year
 * @returns {string} returns comma separated date
 *
 */
export const callDate = date => {
  const { day, month, year } = date
  let months = month
  if (month <= 9) months = '0' + month

  let days = date.day
  if (day <= 9) days = '0' + day

  return `${year}, ${months}, ${days}`
}

/**
 * @function convertDate called to change the key values of goal array
 * @param {string} startDateAndEndDate date
 * @returns {string} returns date
 *
 */
export const convertDate = startDateAndEndDate => {
  const dates = new Date(startDateAndEndDate)
  const year = dates.getFullYear()
  const month = dates.getMonth() + 1
  const day = dates.getDate()
  let months = month
  if (month <= 9) months = '0' + month
  let days = day
  if (day <= 9) days = '0' + day
  return `${year}-${months}-${days}`
}

/**
 * @function getHeaders header
 * @param {string | number} oktaUserId contains id
 * @param {string}  accessToken contains token
 * @returns {object} returns headers data
 *
 */
export const getHeaders = (oktaUserId, accessToken) => {
  const headersData = {
    'okta-user-id': oktaUserId,
    Authorization: accessToken
  }

  return headersData
}

/**
 * @function getHeadersToken headers with page token
 * @param {string | number} oktaUserId contains id
 * @param {string}  accessToken contains token
 * @param {string}  pageToken contains page token
 * @returns {object} returns headers data
 *
 */
export const getHeadersToken = (oktaUserId, accessToken, pageToken) => {
  const headersData = {
    'okta-user-id': oktaUserId,
    Authorization: accessToken,
    'page-token': pageToken
  }

  return headersData
}

export const postApiActionRejection = () => {
  const toast = Toast()
  toast({
    content: 'data.rejected.error.message',
    status: 'error',
    position: 'top',
    duration: 5000,
    isClosable: true
  })

  return (
    <CustomEmptyState
      title={'data.rejected.error.message'}
      image='construction'
      metadata='Service Error'
    />
  )
}

export const processResponse = async (data, getDialogResponse) => {
  let status = POSITIVE
  let bodyContent = data?.payload?.message
  let title = SUCCESS
  if (data.error) {
    status = WARNING
    bodyContent = data?.error?.message
    title = ERROR
  }
  await getDialogResponse({
    dialogStatus: status,
    bodyContent: bodyContent,
    title: title,
    showCancelButton: false
  })
}

/**
 * @function getValues called to get only values
 * @param {array} data contains data
 * @returns {array} returns array values
 *
 */
export const getValues = data => {
  return data?.length ? data?.map(item => item.value) : null
}

/**
 * @function getPartnersData called to convert keys of given data
 * @param {array} data contains data
 * @returns {array} returns array
 *
 */
export const getPartnersData = data => {
  const partners = data?.map(item => {
    return {
      partner: item.displayName,
      partner_id: item.partnerId
    }
  })
  return partners
}
