import React from 'react'
import { Chip, Menu } from '@dentsu-ui/components'
import { DE_ACTIVATE, RUN_NOW } from '../../../constants'

/**
 * @function chipHelper function called when columnsData wants to show chip
 * @param {object} props contains chip value
 */
const chipHelper = props => {
  let status;
  switch (props.value) {
    case 'Active':
      status = (
        <Chip variant='Status' status='positive' hasStatusLight isGhost>
          Active
        </Chip>
      )
      break;
    case 'De-activated':
      status = (
        <Chip variant='Status' hasStatusLight isGhost>
          Deactivated
        </Chip>
      )
      break;
    case 'Complete':
      status = (
        <Chip variant='Status' hasStatusLight isGhost>
          Complete
        </Chip>
      )
      break;
    case 'Error':
      status = (
        <Chip variant='Status' status='error' hasStatusLight isGhost>
          Error
        </Chip>
      )
      break;
    default:
      status = (
        <Chip variant='Status' status='neutral' hasStatusLight isGhost>
          Awaiting Start
        </Chip>
      )
  }
  return status;
}

export const columnsData = [
  {
    Header: 'Status',
    accessor: 'status',
    type: 'custom',
    allowSort: true,
    allowFilter: true,
    Cell: cell => {
      return chipHelper(cell)
    }
  },
  {
    Header: 'Partner',
    id: 'partner_id',
    accessor: 'partner',
    type: 'text',
    allowSort: true
  },
  {
    Header: 'Advertiser',
    id: 'advertiser_id',
    accessor: 'advertiser',
    type: 'text',
    allowSort: true,
    allowFilter: true
  },
  {
    Header: 'Campaign',
    id: 'campaign_id',
    accessor: 'campaign',
    type: 'text',
    allowSort: true,
    allowFilter: true
  },
  {
    Header: 'Insertion Order',
    accessor: 'insertion_order',
    type: 'text',
    allowSort: true,
    allowFilter: true
  },
  {
    Header: 'Campaign Progress',
    accessor: 'campaign_progress',
    type: 'text',
    allowSort: true,
    width: 50
  },
  {
    Header: 'Activation ID',
    accessor: 'channel_id',
    type: 'text',
    allowSort: true,
    width: 50
  },
  {
    Header: 'Enablement type',
    id: 'apply_method',
    accessor: 'apply_method_type',
    allowFilter: true
  },
  {
    Header: 'Campaign dates',
    id: 'start_date',
    type: 'datetime',
    accessor: 'start_date',
    allowFilter: true
  }
  ,
  {
    Header: 'dag_path',
    id: 'dag_path',
    type: 'text',
    accessor: 'dag_path',
    allowFilter: false,
  }, {
    Header: 'optimisation_config_id',
    id: 'optimisation_config_id',
    type: 'text',
    accessor: 'optimisation_config_id',
    allowFilter: false,
  }

]

export const hiddenColumnsData = ['apply_method', 'start_date', 'dag_path', 'optimisation_config_id']

/**
 * @function filteredPartnersData function called to filter partners data
 * @param {array} data contains partners data
 * @returns {array} filtered data
 */
export const filteredPartnersData = data => {
  const result = data?.map(item => {
    return {
      name: `${item.partner} (${item.partner_id})`,
      value: item.partner_id
    }
  })
  return [{ name: 'All Available', value: 'All' }, ...(result || [])]
}
export const dashBoardData = []

export const propsData = data => {
  dashBoardData.length = 0
  dashBoardData.push(data)
}

export const defaultValueSet = () => {
  dashBoardData.length = 0
}

export const dashBoardFilteredResponse = (data, replaceValue = 'NA') => {
  if (!data || data.length === 0) {
    return []
  }
  return data?.map(item => {
    const replacer = (key, value) =>
      String(value) === 'null' || String(value) === '' ? replaceValue : value
    return JSON.parse(JSON.stringify(item, replacer))
  })
}

export const columnData = (
  id,
  selectedFilters,
  selectedSearch,
  history,
  setCell,
  setDialogOpen,
  RunNowHandler,
  userInfo
) => {
  return {
    Header: '',
    accessor: 'insertion_order_id',
    type: 'menu',
    Cell: cell => (
      <Menu>
        <Menu.Button />
        <Menu.List>
          <Menu.Item
            onClick={() => {
              propsData({
                io_id: cell.value,
                campaignName: cell.cell.row.values.campaign_id,
                status: cell.cell.row.values.status,
                config_id: cell?.cell?.row?.original?.optimisation_config_id,
                partner_id: id,
                filters: selectedFilters,
                search: selectedSearch,
                advertiser_id: cell?.cell?.row?.original?.advertiser_id,
                report_id: cell?.cell?.row?.original?.report_id
              })
              history.push('/view-optimization')
            }}
            icon='timeline-bar-chart'
          >
            View Optimisations
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              setCell(cell?.cell?.row?.original?.optimisation_config_id)
              setDialogOpen(true)
            }}
            icon='trash'
            isDisabled={
              (cell.data
                .filter(item => {
                  return (
                    item.optimisation_config_id ===
                    cell?.cell?.row?.original?.optimisation_config_id
                  )
                })
                .map(item => {
                  return item.status === 'De-activated'
                })[0] || userInfo?.role === 'analyst' ? true: false)
            }
          >
            {DE_ACTIVATE}
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              RunNowHandler(cell.cell.row.values.dag_path, cell.cell.row.values.insertion_order_id, cell.cell.row.values.optimisation_config_id)}
            icon='refresh'
            isDisabled={(cell.cell.row.values.status === 'De-activated' || userInfo?.role === 'analyst' ? true: false)}

          >
            {RUN_NOW}
          </Menu.Item>
        </Menu.List>
      </Menu>
    )
  }
}
