import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import AppRouter from '../AppRouter'
import UserAuthenticationContext from '../Shared/UserAuthenticationContext/UserAuthenticationContext'

const AppLayout = () => {
  const { userDetails } = useContext(UserAuthenticationContext)
  const history = useHistory()

  if(!isEmpty(userDetails) && !userDetails.isDentsuUser) {
    history.push('/empty_state')
  }

  return (
    <>
      <AppRouter />
    </>
  )
}

export default AppLayout
