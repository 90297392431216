import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Popover, Stack, Icon, Avatar, Box } from '@dentsu-ui/components'
import {
  StyledLayout,
  StyledLeftContainer,
  StyledHomeOutside,
  StyledHomeInside,
  StyledHeading,
  StyledRightContainer,
  StyledAvatar,
  StyledArrowDownIcon,
  StyledPopoverHover,
  StyledLogoutIcon
} from './Navbar.styled'
import { UNKNOWN_USER } from '../../../../constants/constants'
import UserAuthenticationContext from '../../Shared/UserAuthenticationContext/UserAuthenticationContext'
import { useHistory } from 'react-router-dom'

/**
 * Component Navbar
 * @param {function} logOut function prop
 * @returns {React.ReactElement} returns Navbar component
 */
const Navbar = ({ logOut }) => {
  const history = useHistory()
  const { userDetails } = useContext(UserAuthenticationContext)
  const userInfo = userDetails?.userInfo;
  var item_value = sessionStorage?.getItem("param");
  return (
    <>
      {!item_value ? (
        <div id="nav" style={{ display: 'none' }}>
          <StyledLayout>
            <StyledLeftContainer>
              <StyledHomeOutside>
                <StyledHomeInside onClick={() => history.push('/')}>
                  <Icon icon={'home'} size={16} />
                </StyledHomeInside>
              </StyledHomeOutside>

              <StyledHeading>Activation Hub</StyledHeading>
            </StyledLeftContainer>
            <StyledRightContainer>
              <StyledAvatar>
                <Avatar size='xxlarge' name={userInfo?.name || UNKNOWN_USER} />
              </StyledAvatar>
              <Stack alignItems='center' mt='15px'>
                <Popover placement='bottom-start' hasArrow={true} width={150}>
                  <Popover.Trigger>
                    <StyledArrowDownIcon>
                      <Icon icon={'chevron-down'} size={12} />
                    </StyledArrowDownIcon>
                  </Popover.Trigger>
                  <StyledPopoverHover>
                    <Popover.Content>
                      <Box onClick={() => logOut()}>
                        <StyledLogoutIcon icon={'log-out'} size={14} />
                        Logout
                      </Box>
                    </Popover.Content>
                  </StyledPopoverHover>
                </Popover>
              </Stack>
            </StyledRightContainer>
          </StyledLayout>
        </div>
      ) : <></>
      }
    </>
  )
}

Navbar.propTypes = {
  logOut: PropTypes.func
}

export default Navbar
