import { lazy } from 'react'
import { LoginCallback } from '@okta/okta-react'
import { dashBoardData } from '../Dashboard/Helper'

const Dashboard = lazy(() => import('../Dashboard'))
const NewOptimization = lazy(() => import('../NewOptimization'))
const ViewOptimization = lazy(() => import('../ViewOptimization'))
const UserManagement = lazy(() => import('../UserManagement'))
const CustomEmptyState = lazy(() => import('../Shared/CustomEmptyState'))
const LinkedAccounts = lazy(() => import('../LinkedAccounts'))
const Governance = lazy(() => import('../Governance'))
const CampaignSetup = lazy(() => import('../CampaignSetup'))
const Measurement = lazy(() => import('../Measurement'))
const SupplyPackage = lazy(() => import('../SupplyPackage'))
const NewSupplyPackage = lazy(() => import('../NewSupplyPackage'))
const ViewDeal =  lazy(() => import('../SupplyPackage/ViewDeal'))
const Optimisation = lazy(()=>import('../Optimisation'))
const SupplyManagement = lazy(() => import('../SupplyManagement'))
const DataSources = lazy(() => import('../SupplyManagement/DataSources'))
const NewInventoryList = lazy(() => import('../NewInventoryList'))
const ViewInventoryList = lazy(() => import('../SupplyManagement/ViewInventoryList'))
const EditInventoryList = lazy(() => import('../SupplyManagement/EditInventoryList'))
const Logout = lazy(() =>  import('./Logout'))

export const routes = {
  home: {
    path: '/',
    component: Dashboard,
    isSecure: true,
    exact: true
  },
  login: {
    // path: '/implicit/callback', // Dentsu Okta Integration
    path: '/login/callback',    
    component: LoginCallback,
    isSecure: false
  },
  logout: {
    path: '/logout',    
    component: Logout,
    isSecure: false
  },
  newOptimization: {
    path: '/new-optimization',
    component: NewOptimization,
    isSecure: true
  },
  viewOptimization: {
    path: '/view-optimization',
    component: ViewOptimization,
    isSecure: true,
    props: dashBoardData
  },
  userManagement: {
    path: '/user-management',
    component: UserManagement,
    isSecure: true
  },
  linkedAccounts: {
    path: '/linked-accounts',
    component: LinkedAccounts,
    isSecure: true
  },
  customEmptyState: {
    path: '/empty_state',
    component: CustomEmptyState,
    isSecure: false
  },
  governance: {
    path: '/governance',
    component: Governance,
    isSecure: true
  },
  campaignSetup: {
    path: '/campaign-setup',
    component: CampaignSetup,
    isSecure: true
  },
  targeting: {
    path: '/marketplace',
    component: SupplyPackage,
    isSecure: true
  },
  measurement: {
    path: '/measurement',
    component: Measurement,
    isSecure: true
  },
  newSupplyPackage: {
    path: '/new-supply-package',
    component: NewSupplyPackage,
    isSecure: true
  },
  viewDeal: {
    path: '/view-deal',
    component: ViewDeal,
    isSecure: true,
  },
  optimisation: {
    path: '/optimisation',
    component: Optimisation,
    isSecure: true
  },
  supplyManagement: {
    path: '/inventory-list',
    component: SupplyManagement,
    isSecure: true
  },
  
  dataSources: {
    path: '/datasources',
    component: DataSources,
    isSecure: true
  },
  newInventoryList: {
    path: '/new-inventory-list',
    component: NewInventoryList,
    isSecure: true
  },
  viewInventoryList: {
    path: '/view-inventory-list',
    component: ViewInventoryList,
    isSecure: true
  },
  editInventoryList: {
    path: '/edit-inventory-list',
    component: EditInventoryList,
    isSecure: true
  },
}
