import { AS, CPAU } from '../../../constants'
export const programmaticChooserData = (data = []) => {
  return data.map(item => {
    return {
      id: item.id,
      title: item.name,
      subtitle: item.description,
      value: item.value,
      image: `/assets/${item.image}.svg`
    }
  })
}
export const DataSourcesChooser= (data = []) => {
  return data.map(item => {
    return {
      id: item.ssp_id,
      title: item.title,
      subtitle: item.description,
      value: item.title,
      image: item.image,
      last_updated_at: item.last_updated_at,
      abbreviation: item.abbreviation,
      bucket_location: item.bucket_location,
      category_id: item.category_id
    }
  })
}

export let disabled_data_sources = []
export let all_Data_Sources = []
export const disableDataSources = (data) => {
  disabled_data_sources = []
  all_Data_Sources = []
  const mediaSources = data?.media_verification ? data?.media_verification : []
  const DBASources = data?.brand_assurance ? data?.brand_assurance : []
  const SSPSources = data?.ssp ? data?.ssp : []
  const allDataSources = DBASources?.concat(mediaSources, SSPSources)
  all_Data_Sources?.push(DataSourcesChooser(allDataSources))
  const disableSources = allDataSources?.filter((dataSource) => (dataSource?.ssp_name === 'Xandr' || dataSource?.ssp_name === 'Green Web Foundation' || ( dataSource?.ssp_name === 'GoodIQ by GoodNet' && sessionStorage.getItem('referrer_env') === 'prod' ) || !dataSource?.last_updated_at ))?.map(dataSource => dataSource?.title)
  disabled_data_sources.push(disableSources)
}
export const dealCountData = []
export const countDeal = (data) => {
  dealCountData.push(data)
}

export const prepareStatus = res => {
  return res.map(item => {
    if (item.status === 'De-active') {
      return { ...item, status: 'De-activated' }
    }
    if (item.status === 'Completed') {
      return { ...item, status: 'Complete' }
    }
    return { ...item }
  })
}

export const preparePartnersData = (res = [], id) => {
  if (id === 'All') {
    return res
  } else {
    return res?.filter(item => {
      return item?.partner_id?.toString() === id
    })
  }
}

export const prepareKPIOptionsData = data => {
  return data.map(item => {
    const { value } = item
    const values = [AS, CPAU]
    const disable = values.includes(value)
    return {
      kpi_target: item.value,
      kpi_goal: 0,
      kpi_unit: item.type,
      label: item.name,
      value: item.value,
      isDisabled: disable
    }
  })
}

export let NextPageTokenData = ''
export const saveToken = data => {
  NextPageTokenData = data?.nextPageToken || ''
}
export let NextPageLITokenData = ''
export const saveLIToken = data => {
  NextPageLITokenData = data?.nextPageToken || ''
}

export let NextPageCampaignToken = ''
export const saveCampaignToken = data => {
  NextPageCampaignToken = data?.nextPageToken || ''
}

export let NextPageIOToken = ''
export const saveIOToken = data => {
  NextPageIOToken = data?.nextPageToken || ''
}

export const prepareIOOptionsData = data => {
  return data.map(item => {
    if (item.reservationType !== 'RESERVATION_TYPE_NOT_GUARANTEED') {
      return { ...item, isDisabled: true }
    }
    return { ...item, isDisabled: false }
  })
}

export let AdvertiserData = []
export const prepareAdvertiserData = res => {
  AdvertiserData = res
}

export let ExchangeData = []
export const prepareExchangeData = res => {
  ExchangeData = res
}

export let MarketData = []
export const prepareMarketData = res => {
  MarketData = res
}

export let SupplyData = []
export const prepareSupplyData = res => {
  SupplyData  = res
}

export let loadCuratedList =[]
export const prepareLoadInventoryList =res =>{
  loadCuratedList =res
}
export let getInvnetoryCuratedList =[]
export const prepareGetInventoryList =res =>{
  loadCuratedList =res
}
export let ContentDataLoader = []
export const ContentCategoryLoader = res => {
  ContentDataLoader = res
}
export let DealData = []
export const prepareDealData = res => {
  DealData = res
}

export let CampaignData = []
export const prepareCampaignData = res => {
  CampaignData = res
}
export let LineItemData = []
export const prepareLineItemData = res => {
  LineItemData = res
}
export let VerifyIoData = []
export const prepareVerifyIoData = res => {
  VerifyIoData = res
}

export let IoData = []
export const prepareIoData = res => {
  IoData = res
}

export const prepareOptimisationPerformanceData = (res) => {
  return res?.length > 0 ? res?.[0] : []
}

export const prepareOptimisationHistory = (res) => {
  return res
}

export const nextPageData = async (services, headers, getCall, id, value) => {
  let resultArray
  let res
  let pageToken
  res = await services[getCall](id, headers)
  pageToken = res?.data?.nextPageToken
  resultArray = res?.data[value]
  if (pageToken) {
    do {
      res = await services[getCall](id, headers, pageToken)
      pageToken = res.data?.nextPageToken
      if (res?.data[value]) resultArray = resultArray.concat(res?.data[value])
    } while (pageToken)
  }
  return resultArray
}


export const dashBoardUpdatedResponse = (data) => {

  if (!data || data.length === 0) {
    return []
  }
  return data?.map(item => {
    if (item.status === 'active') {
      return { ...item, status: 'Active' }
    } else if (item.status === 'De-active') {
      return { ...item, status: 'Deactivated' }
    }
    return item
  })
}

export const publisherDomainListUpdatedResponse = (data) => {

  if (!data || data.length === 0) {
    return []
  }
  return data?.map((item, index) => {
    return {
      ...item,
      isExpand: false,
      isSelected: true,
      isIndeterminate: false,
      unique_id: index + 1,
      count: item?.values?.length
    }
  })
}
