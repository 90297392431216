import { combineReducers, configureStore } from '@reduxjs/toolkit'
import campaignOptimization from './features/campaign-optimization/campaignOptimizationSlice'
import userManagement from './features/user-management/userManagementSlice'
import linkedAccounts from './features/linked-accounts/linkedAccountsSlice'
import governance from './features/governance/governanceSlice'
import campaignSetup from './features/campaign-setup/campaignSetupSlice'
import targeting from './features/Targeting/targetingSlice'
import measurement from './features/Measurement/measurementSlice'
import storage from 'redux-persist/lib/storage';
import {
  persistReducer, persistStore, FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import supplyManagementSlice from './features/supplyManagement/supplyManagementSlice'

const persistConfig = {
  key: 'root',
  storage,
}
const rootReducer = combineReducers({
  campaignOptimization: campaignOptimization,
  userManagement: userManagement,
  linkedAccounts: linkedAccounts,
  campaignSetup: campaignSetup,
  targeting: targeting,
  measurement: measurement,
  governance: governance,
  supplyManagement : supplyManagementSlice,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
export const persistor = persistStore(store)