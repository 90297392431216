import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, Button } from '@dentsu-ui/components'

/**
 * Component CustomDialog
 * @returns {React.ReactElement} returns CustomDialog component
 */
const CustomDialog = React.memo(
  ({
    title,
    dialogOpen,
    dialogClose,
    bodyContent,
    variant,
    handleNegativAction,
    actionText,
    closeText,
    handlePositiveAction,
    showCancelButton,
    dialogStatus
  }) => {
    return (
      <Dialog
        status={dialogStatus}
        title={title}
        isOpen={dialogOpen}
        onClose={dialogClose}
      >
        <Dialog.Body>
          <p>{bodyContent}</p>
        </Dialog.Body>

        <Dialog.Footer>
          {showCancelButton && (
            <Button variant={variant} onClick={handleNegativAction}>
              {closeText}
            </Button>
          )}

          <Button onClick={handlePositiveAction}>{actionText}</Button>
        </Dialog.Footer>
      </Dialog>
    )
  }
)

CustomDialog.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  showCancelButton: PropTypes.bool,
  title: PropTypes.string,
  dialogClose: PropTypes.func,
  bodyContent: PropTypes.string,
  handleNegativAction: PropTypes.func,
  closeText: PropTypes.string,
  variant: PropTypes.string,
  handlePositiveAction: PropTypes.func,
  actionText: PropTypes.string,
  dialogStatus: PropTypes.string
}

CustomDialog.defaultProps = {
  dialogOpen: false,
  closeText: 'Cancel',
  showCancelButton: true,
  dialogStatus: '',
  title: 'Confirm',
  bodyContent: 'Are you sure you want to proceed?',
  actionText: 'Ok',
  variant: 'secondary'
}

export default CustomDialog
