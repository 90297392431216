import http from './http-commonSupply'

class ServiceSupply{
  getDataSourceType(headers) {
    return http.get('/supply-manager/data_provider', { headers: headers })
  }

  InventoryListVerify(req_data, headers) {
    return http.post(`/supply-manager/verify_inventory_list_name`, req_data, { headers: headers })
  }

  getInventoryList(headers) {
    return http.get('/supply-manager/inventory_lists', {
      headers: headers
    })
  }

  getStaticData(req_data, yaml_type, headers) {
    return http.post(`/supply-manager/static/data?yaml_type=${yaml_type}`, req_data, { headers: headers })
  }

  applyPrefilters(req_data, headers, type) {
    return http.post(`/supply-manager/create-yaml?yaml_type=${type}`, req_data, { headers: headers })
  }

  getCurateInventoryList(req_data, headers, type) {
    return http.post(`/supply-manager/apply/rules?yaml_type=${type}`, req_data, { headers: headers })
  }

  deactivateInventoryList(data, headers) {
    return http.put(`/supply-manager/delete_inventory_list`, data, {
      headers: headers
    })
  }
  loadCuratedInventoryList(req_data, headers) {
    return http.post(`/supply-manager/load_inventory_list`, req_data, { headers: headers })
  }

  uploadUpdatedInventoryList(formData, file_extn, data_source, headers) {
    return http.post(`/supply-manager/save/curated_inventory_list?data_source=${data_source}&file_extn=${file_extn}`, formData, { headers: headers })
  }

  createInventoryList(req_data, headers) {
    return http.post('/supply-manager/create/inventory', req_data, { headers: headers})
  }

  platformAccountList(headers) {
    return http.get('/supply-manager/list/accounts', { headers: headers})
  }

  addNewDataSource(req_data, headers) {
    return http.post('/supply-manager/add/data_source', req_data, { headers: headers})
  }

  uploadLogo(formData, headers) {
    return http.post('/supply-manager/upload/image', formData, { headers: headers})
  }

  viewDataSource(id, headers) {
    return id && http.get(`/supply-manager/view/data_source?id=${id}`, { headers: headers})
  }

  updateDataSource(data, id, headers) {
    return http.put(`/supply-manager/view/data_source?id=${id}`, data, { headers: headers})
  }

  deleteDataSource(id, headers) {
    return http.delete(`/supply-manager/view/data_source?id=${id}`, { headers: headers })
  }

  viewInventoryList(id, ssp_id, headers) {
    return id && http.get(`/supply-manager/view/inventory_list?id=${id}&ssp_id=${ssp_id}`, { headers: headers})
  }

  editInventoryList(req_data, id, ssp_id, headers) {
    return id && http.put(`/supply-manager/view/inventory_list?id=${id}&ssp_id=${ssp_id}`, req_data, { headers: headers})
  }

  exportInventoryList(req_data, headers) {
    return http.post(`/supply-manager/download/inventory_list`, req_data, { headers: headers})
  }

  dealInventoryList(id, headers) {
    return id && http.get(`/supply-manager/deal/inventory_list?ssp_id=${id}`, { headers: headers})
  }

  duplicateInventoryList(req_data, headers) {
    return http.post(`/supply-manager/duplicate/inventory_list`, req_data, { headers: headers})
  }
}
export default new ServiceSupply()