import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import services from '../../../services/services'
import { getHeaders } from '../../../common/helper'

export const getLinkedAccount = createAsyncThunk(
  'get/getLinkedAccount',
  async ({ oktaUserId, accessToken }) => {
    const res = await services.getLinkedAccount(
      getHeaders(oktaUserId, accessToken)
    )
    return res?.data?.data
  }
)

const initialState = {
  linkedAccountDetails: []
}

const resetState = {
  linkedAccountDetails: []
}

const linkedAccountsSlice = createSlice({
  name: 'linkedAccounts',
  initialState: initialState,
  reducers: {
    reset: state => ({ ...state, ...resetState })
  },
  extraReducers: {
    [getLinkedAccount.pending]: state => {
      state.loading = true
      state.linkedAccountDetails = []
    },
    [getLinkedAccount.rejected]: (state, action) => {
      state.loading = false
      state.linkedAccountDetails = []
      state.rejected = action
    },
    [getLinkedAccount.fulfilled]: (state, action) => {
      state.loading = false
      state.linkedAccountDetails = action.payload
    }
  }
})

export const { reset } = linkedAccountsSlice.actions

export default linkedAccountsSlice.reducer
