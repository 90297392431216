import React from 'react'
import PropTypes from 'prop-types'
import { EmptyState, Paragraph } from '@dentsu-ui/components'
import { useOktaAuth } from '@okta/okta-react'
import NavBar from '../Navbar'

/**
 * Component CustomEmptyState
 * @param {string} image contains image data
 * @param {string} metadata contains metadata
 * @param {string} title contains title
 * @param {boolean} showNav contains true or false
 * @returns {React.ReactElement} returns CustomEmptyState component
 */
const CustomEmptyState = ({ image, metadata, title, showNav }) => {
  const { oktaAuth } = useOktaAuth()
  const logOut = () => oktaAuth.signOut()

  return (
    <>
      {showNav ? <NavBar logOut={logOut} /> : null}
      <EmptyState
        layout='horizontal'
        size='small'
        image={image}
        metadata={metadata}
        title={title}
      >
        <Paragraph>Please contact your administrator.</Paragraph>
      </EmptyState>
    </>
  )
}

CustomEmptyState.defaultProps = {
  showNav: true,
  image: 'superuser',
  metadata: 'ERROR CODE: 404',
  title: 'Sorry, you don’t have permission to access this page'
}

CustomEmptyState.propTypes = {
  image: PropTypes.string,
  metadata: PropTypes.string,
  title: PropTypes.string,
  showNav: PropTypes.bool
}

export default React.memo(CustomEmptyState)
