import React, { Suspense } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, useHistory } from 'react-router-dom'
import { Security, SecureRoute } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { Loading } from '@dentsu-ui/components'
import { routes } from './routes'

const CLIENT_ID = '0oa4r8w4a9njcBzRB417'
const ISSUER = 'https://myapps-hybrid.dentsu.com/oauth2/default'

// const CLIENT_ID = '0oa89xk9kt5OwFLvG5d7'
// const ISSUER = 'https://dev-90506287.okta.com/oauth2/default'

// DENTSU OKTA INTEGRATION
// const ISSUER = "https://login.np.mediaecosystem.io/oauth2/ausevsk7dvZoZWaXh416";
// const CLIENT_ID = "0oaa8ezsnQtbiGeFk416";

console.log(':::: Document Referrer ::::', document.referrer);
console.log(':: ISSUER ::', process.env.REACT_APP_INT_OKTA_ISSUER);
console.log(':: CLIENT_ID ::', process.env.REACT_APP_INT_OKTA_CLIENT_ID);
console.log(':: AUDIENCE ::', process.env.REACT_APP_DEFAULT_OKTA_AUDIENCE);
console.log(':: NFT ISSUER ::', process.env.REACT_APP_NFT_OKTA_ISSUER);
console.log(':: NFT CLIENT_ID ::', process.env.REACT_APP_NFT_OKTA_CLIENT_ID);



const oktaConfig = {
  issuer: ISSUER,
  redirectUri: window.location.origin + '/login/callback',
  // redirectUri: window.location.origin + '/implicit/callback', // Dentsu OKTA INTEGRATION
  clientId: CLIENT_ID
}

const oktaAuth = new OktaAuth(oktaConfig)

const getSecureRoute = (route, index) => {
  return (
    <SecureRoute key={index} path={route.path} exact={route.exact}>
      {route.component && <route.component dashboardData={route.props} />}
    </SecureRoute>
  )
}

/**
 * @function getRoute called to render routes
 * @param {object} route contains route data
 * @param {number} index contains index value
 *
 */
const getRoute = (route, index) => {
  return (
    <Route key={index} path={route.path} exact={route.exact}>
      {route.component && <route.component dashboardData={route.props} />}
    </Route>
  )
}

const renderRoutes = () => {
  return Object.values(routes).map((route, index) =>
    route.isSecure ? getSecureRoute(route, index) : getRoute(route, index)
  )
}

const AppRouter = () => {
  const history = useHistory()
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin))
  }

  return (
    <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
      <Suspense fallback={<Loading>Loading...</Loading>}>
        <Switch>{renderRoutes()}</Switch>
      </Suspense>
    </Security>
  )
}

AppRouter.propTypes = {
  location: PropTypes.string
}

export default AppRouter