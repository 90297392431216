import inlcudes from 'lodash/includes'
import without from 'lodash/without'

export const userRoleOptions = [
  { value: 'admin', label: 'Admin' },
  { value: 'trader', label: 'Trader' },
  { value: 'analyst', label: 'Analyst' }
]

/**
 * @function processPartnersData to process partners data
 * @param {array} data partner data
 * @returns {array} returns modified partners data
 *
 */
export const processPartnersData = data => {
  if (!data || data.length < 1) return []

  return data?.map(item => {
    return { value: item.partnerId, label: item.displayName }
  })
}

/**
 * @function processOptimisationTypesData Optimisation Types Data
 * @param {array} data contains data
 * @returns {array} returns modified data
 *
 */
export const processOptimisationTypesData = data => {
  if (!data || data.length < 1) return []

  return data?.map(item => {
    return { value: item.id, label: item.title, subtitle: item.subtitle, image: item.image, valueName: item.value }
  })
}

export const processCardsData = data => {
  if (!data || data.length < 1) return []

  return data?.map(item => {
    return { value: item.id, label: item.name }
  })
}
export const processAllCardsData = data => {
  if (!data || data.length < 1) return []

  return data?.map(item => {
    return { name: item.name, id: item.id }
  })
}

/**
 * @function prepareSubmitData contains submit data
 * @param {array} checkedPartners contains checked partners
 * @param {array} checkedApps contains checked apps
 * @param {array} partners contains all partners
 * @param {array} optimisationTypes contains optimisationTypes
 * @returns {object} returns the data before submission
 *
 */
export const prepareSubmitData = (
  checkedPartners,
  checkedApps,
  partners = [],
  optimisationTypes = [],
  checkedGovCards,
  checkedCampaignSetupCards,
  checkedMeasurementCards,
  checkedTargetingCards,
  governanceCards,
  campaignSetupCards,
  targetingCards,
  measurementCards,
  checkedMediaExchange,
  mediaExchanges
) => {
  const partnersData = partners?.map(partner => {
    if (inlcudes(checkedPartners, partner.value)) {
      return {
        partner_id: Number(partner.value),
        partner: partner.label
      }
    }
  })

  const appsData = optimisationTypes?.map(type => {
    if (inlcudes(checkedApps, type.value)) {
      return {
        id: type.value,
        image: type.image,
        description: type.subtitle,
        name: type.label,
        value: type.valueName
      }
    }
  })

  const govData = governanceCards?.map(type => {
    if (inlcudes(checkedGovCards, type.value)) {
      return {
        name: type.label,
        id: type.value
      }
    }
  })

  const campaignData = campaignSetupCards?.map(type => {
    if (inlcudes(checkedCampaignSetupCards, type.value)) {
      return {
        name: type.label,
        id: type.value
      }
    }
  })

  const targetingData = targetingCards?.map(type => {
    if (inlcudes(checkedTargetingCards, type.value)) {
      return {
        name: type.label,
        id: type.value
      }
    }
  })

  const measurementData = measurementCards?.map(type => {
    if (inlcudes(checkedMeasurementCards, type.value)) {
      return {
        name: type.label,
        id: type.value
      }
    }
  })

  const mediaExchangeData = mediaExchanges?.map(type => {
    if (inlcudes(checkedMediaExchange, type.value)) {
      return type.name
    }
  })

  return {
    partners: without(partnersData, undefined),
    optimisation_type: without(appsData, undefined),
    governance_cards: without(govData, undefined),
    measurement_cards: without(measurementData, undefined),
    campaign_setup_cards: without(campaignData, undefined),
    optimisation_cards: without(targetingData, undefined),
    api_permissions: without(mediaExchangeData, undefined),
  }
}

export const mediaExchangeOptions = [
  { id: 1, label: 'Deal Management', value: 1, name: 'deal_management' },
  { id: 2, label: 'Supply Management', value: 2, name: 'supply_management' }
]

/**
   * @function getAllPartnersIds gets all ids
   * @returns {array} all partner ids
   *
   */
export const getAllPartnersIds = (campaignOptimizationsData) => {
  return campaignOptimizationsData?.partners?.partners?.map(
    partner => partner.partnerId
  )
}

/**
 * @function getAllAppIds gets all application ids
 * @returns {array} all app ids
 *
 */
export const getAllAppIds = (optimisationTypes) => {
  return optimisationTypes?.map(type => type.value)
}

export const getGovCardIds = (governanceCards) => {
  return governanceCards?.map(card => card?.value)
}

export const getCampaignSetupCardIds = (campaignSetupCards) => {
  return campaignSetupCards?.map(card => card?.value)
}

export const getTargetingCardIds = (targetingCards) => {
  return targetingCards?.map(card => card?.value)
}

export const getMeasurementCardIds = (measurementCards) => {
  return measurementCards?.map(card => card?.value)
}

export const getMediaExchangeIds = (mediaExchanges) => {
  return mediaExchanges?.map(exchange => exchange?.value)
}