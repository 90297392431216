import React, { useState } from 'react'
import PropTypes from 'prop-types'
import GlobalDialogContext from './GlobalDialogContext'

const intitialState = {
  dialogProps: null,
  resolver: null,
  showDialog: false
}

/**
 * Provider for the global modal context values.
 *
 * @param {object} props components props
 * @param {Array} props.children the child elements
 * @returns {React.Component} GlobalModalProvider component
 */
function GlobalDialogProvider({ children }) {
  const [state, setState] = useState(intitialState)

  const getDialogResponse = dialogProps => {
    return new Promise(resolver => {
      setState({ dialogProps, resolver, showDialog: true })
    }
    )
  }

  const { dialogProps, resolver, showDialog } = state

  const resolveDialog = action => () => {
    resolver(action)
    setState(intitialState)
  }

  const contextValue = {
    getDialogResponse,
    dialogProps,
    resolveDialog,
    showDialog
  }

  return (
    <GlobalDialogContext.Provider value={contextValue}>
      {children}
    </GlobalDialogContext.Provider>
  )
}

GlobalDialogProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
}

export default GlobalDialogProvider
