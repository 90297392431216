import React, { useContext } from 'react'
import Dialog from '../Dialog'
import GlobalDialogContext from './GlobalDialogContext'

/**
 * To be used only once in the application. Works with the
 * context values to display a modal from anywhere in the app.
 *
 * @returns {React.Component} GlobalDialog component
 */
function GlobalDialog() {
  const { dialogProps, resolveDialog, showDialog } = useContext(GlobalDialogContext)
  return showDialog ? (
    <Dialog
      dialogOpen
      handleNegativAction={resolveDialog(false)}
      handlePositiveAction={resolveDialog(true)}
      {...dialogProps}
    />

  ) : null
}

export default GlobalDialog
